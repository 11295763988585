<div class="modal-content">
  <div class="modal-header">
    <h6
      *ngIf="create === true"
      class="modal-title"
      id="modal-title-default"
      [translate]="'CRIAR_CONTATO_CLIENTE'"
    ></h6>
    <h6 *ngIf="create === false" class="modal-title" id="modal-title-default">
      {{ "EDITAR_CONTATO" | translate }}
    </h6>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="modal.close()"
    >
      <i class="mdi mdi-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label [translate]="'DESCRICAO'"></label>
          <input
            type="text"
            class="form-control"
            [ngClass]="{
              required: verificaRequerido(clienteContato.descricao)
            }"
            [(ngModel)]="clienteContato.descricao"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label [translate]="'EMAIL'"></label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="clienteContato.email"
            [ngClass]="{
              'email-invalido': !validateEmail(clienteContato.email)
            }"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <label>{{ "DDG" | translate }}</label>
          <div>
            <label class="switch">
              <input
                type="checkbox"
                [checked]="is_ddg"
                (change)="checkDdg($event)"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="row">
          <div class="form-group col-4" *ngIf="!is_ddg">
            <label [translate]="'DDI'"></label>
            <select-padrao
              [entidade]="'selectCodigoDdiPais'"
              [required]="false"
              [model]="codigo_pais"
              [returnObj]="true"
              (selectChange)="changeDdi($event)"
            ></select-padrao>
          </div>
          <div
            class="form-group col-8"
            *ngIf="!is_ddg && render && clienteContato.codigo_pais"
          >
            <label [translate]="'TELEFONE'"></label>
            <input
              class="form-control"
              *ngIf="clienteContato.codigo_pais.valor === '55'"
              type="text"
              [mask]="mascaraTel"
              [(ngModel)]="clienteContato.telefone"
            />
            <input
              class="form-control"
              *ngIf="clienteContato.codigo_pais.valor !== '55'"
              type="text"
              [mask]="mascaraTel"
              [(ngModel)]="clienteContato.telefone"
            />
          </div>
          <div class="form-group col-12" *ngIf="is_ddg">
            <label>DDG</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="ddifixo">+55</span>
              </div>
              <input
                class="form-control"
                type="text"
                [(ngModel)]="clienteContato.telefone"
                mask="0000 000 0000"
                aria-describedby="ddifixo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4" *ngIf="render && clienteContato.codigo_pais">
        <div class="form-group">
          <label [translate]="'CELULAR'"></label>
          <input
            type="text"
            class="form-control"
            [mask]="mascaraCel"
            [(ngModel)]="clienteContato.celular"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label>Whatsapp</label>
          <select
            class="form-control"
            [(ngModel)]="clienteContato.possui_whatsapp"
          >
            <option value="true" [translate]="'SIM'"></option>
            <option value="false" [translate]="'NAO'"></option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label [translate]="'PADRAO'"></label>
          <select
            class="form-control"
            [ngClass]="{ required: clienteContato.is_padrao === '' }"
            [(ngModel)]="clienteContato.is_padrao"
          >
            <option value="true" [translate]="'SIM'"></option>
            <option value="false" [translate]="'NAO'"></option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label [translate]="'OBSERVACAO'"></label>
          <!-- <textarea
            class="form-control"
            rows="3"
            style="resize: none"
            [(ngModel)]="clienteContato.observacao"
          ></textarea> -->
          <form [formGroup]="observacaoForm">
            <app-text-editor 
              formControlName="observacao" 
              [textEditorConfig]="quillConfigEditor" 
              [textEditorRequired]="false">
            </app-text-editor>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-success badge-pill"
      [translate]="'SALVAR'"
      [disabled]="verificaSeDesativa(clienteContato)"
      (click)="salvaContato(clienteContato)"
    ></button>
  </div>
</div>
