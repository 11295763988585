import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class ListagemAlertasService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() }),
    params: {}
  };

  constructor(private tokenService: TokenService, private http: HttpClient) { }

  getDropdownList(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/dispositivos/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropDownListAlertas(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/alertas/tipo/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropDownListGravidade(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/Alerta/gravidadeAlerta/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  listagemPorUsuario(params, filtros): Observable<any> {
    this.httpOptions.params = params
    return this.http.post(`${this.apiUrl}api/Alerta/listUsers`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
