import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';
import { UsuarioOpcoes } from './../components/filtros-padrao/filtros-padrao.component';
import { FiltroModel } from '../components/filtros-padrao/modal-filtros/modal-filtros.component';

@Injectable({
  providedIn: 'root'
})
export class FiltroPadraoService {
  private apiUrl = environment.apiGestorUrlNova;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
    params: {}
  };
   
  constructor(private http: HttpClient, private tokenService: TokenService) { }

  buscarFiltrosPorCategoria(payload: Object): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}manager/filters/list`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  alteraOpcoesFiltro(filtro_id: number, payload: UsuarioOpcoes): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}manager/filters/relation/${filtro_id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getFiltro(filtro_id: number): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}manager/filters/${filtro_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  criarFiltro(payload: FiltroModel): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}manager/filters`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateFiltro(filtro_id: number, payload: FiltroModel): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}manager/filters/${filtro_id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteFiltro(filtro_id: number): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}manager/filters/${filtro_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownEntidades(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}manager/filters/dropdown/entities`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownCampos(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}manager/filters/dropdown/fields`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownOperadores(payload): Observable<any> {
    return this.http.post(`${this.apiUrl}manager/filters/dropdown/operators`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownValores(chave: string): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}manager/filters/dropdown/${chave}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  sendFeedbackFiltros(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}manager/feedbacks/sendFeedback`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
