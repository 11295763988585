<tag-input [(ngModel)]='selectedValues' 
           [placeholder]="'E-mail'" 
           [secondaryPlaceholder]="'E-mail'"         
           [onAdding]="onAddingTag"
           (onAdd)="onAdding($event)"
           (onRemove)="onRemove($event)"
           theme="regular-theme"
           [ngStyle]="{'border-top': required === true && selectedValues.length === 0 ? '2px solid red' : ''}"
           name="tags"
           class="ng-invalid"
           id="tagsInput">
</tag-input>