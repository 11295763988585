<div class="modal-content">
    <div class="modal-header">
      <h6 *ngIf="create" class="modal-title" id="modal-title-default" [translate]="'NOVA_TAREFA'"></h6>
      <h6 *ngIf="!create" class="modal-title" id="modal-title-default" [translate]="'EDITAR_TAREFA'"></h6>
      <button id="btn-fechar-modal-tarefa" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close()">
        <i class="mdi mdi-close"></i>
      </button>
    </div>
    <!-- body -->
    <div class="modal-body">
        <div class="row">
            <div *ngIf="visualizacao === false" class="col-8" style="border-right: 1px solid #cfd1d3;">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label [translate]="'TITULO'"></label>
                            <input class="form-control" type="text" id="titulo" maxlength="100" 
                            placeholder="{{ 'TITULO' | translate }}" [ngClass]="{'required': validTitle()}"  [(ngModel)]="tarefa.titulo">
                        </div>
                    </div>
                </div>
                <div class="row" id="infoBasica">
                    <div class="col-3">
                        <div class="form-group">
                            <label [translate]="'OPERADOR'"></label>
                            <select-padrao id="select-operador-tarefa" [entidade]="'selectTecnico'" [required]="true" [returnObj]="true" [model]="tarefa.tecnico_id" (selectChange)="tarefa.tecnico_id = $event; buscarTarefasAgendadas(); validAuthTecnicoGoogle(tarefa);" [disabled]="!create || info_tarefa || minha_agenda_tarefa"></select-padrao>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label [translate]="'TIPO_TAREFA'"></label>
                            <select-padrao id="select-tipo-tarefa-tarefa" [entidade]="'selectTipoTarefa'" [returnObj]="true" [model]="tarefa.tipo_tarefa_id" [params]="{'is_ativo': true}" (selectChange)="tarefa.tipo_tarefa_id = $event" [disabled]="checkIfDisableTipoTarefa()"></select-padrao>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label [translate]="'AGENDAMENTO'"></label>
                            <datepicker-padrao id="datepicker-agendamento-tarefa" [model]="tarefa.data_agendamento" [required]="(tarefa.data_agendamento === '' || tarefa.data_agendamento === null)" (dateChange)="tarefa.data_agendamento = $event; buscarTarefasAgendadas($event)" [disabled]="!create" [dataMinima]="hoje"></datepicker-padrao>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label [translate]="'TEMPO_EXECUTAR_TAREFA'"></label>
                            <hourpicker-padrao id="hourpicker-tempo-executar-tarefa" [model]="horasTask" [required]="true" (hourChange)="verificaHoras($event)"></hourpicker-padrao>
                        </div>
                    </div>
                </div>
                <span *ngIf="hasIntegrationGoogleSchedule || hasIntegrationOutlookSchedule">                
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label [translate]="'CONVIDADOS_EMAIL'"></label>
                                <input-tags-padrao
                                    id="input-guests-email"
                                    [sendAsArray]="true"
                                    [required]="false"
                                    (selectChange)="addGuest($event)"
                                    (showError)="showInvalidEmail()"
                                    [model]="listGuests"
                                ></input-tags-padrao>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="create-event" [(ngModel)]="createEvent">
                                <label class="custom-control-label" for="create-event" style="padding-top: 4px;">{{'CRIAR_EVENTO_AGENDA_INTEGRADA' | translate}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" >
                            <div class="form-group custom-control custom-checkbox" *ngIf="create">
                                <input type="checkbox" class="custom-control-input"  id="video-conf" [(ngModel)]="videoConf">
                                <label class="custom-control-label" for="video-conf" style="padding-top: 4px;">{{'ADICIONAR_VIDEOCONFERENCIA' | translate}}</label>                            
                            </div>
                            <div class="form-group" *ngIf="!create" [innerHtml]="linkMeet" style="margin-top: -10px; margin-bottom: 10px;">
                            </div>
                        </div>                    
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label [translate]="'LOCAL'"></label>
                                <input class="form-control" type="text" id="local" maxlength="100" 
                                placeholder="{{ 'DESCREVA' | translate }}..." [(ngModel)]="local">
                            </div>
                        </div>
                    </div>
                </span>
                <div class="row" [formGroup]="newTaskForm">
                    <div class="col-12">
                        <div class="form-group">
                            <label [translate]="'DESCRICAO'"></label>
                            <app-text-editor
                                id="editor-texto-padrao-descricao"
                                formControlName="description"
                                [textEditorConfig]="quillConfigEditor"
                                [textEditorRequired]="checkFields('description')"
                            >
                            </app-text-editor>
                        </div>
                    </div>
                </div>
                <div class="row" id="anexo">
                    <div class="col-12">
                        <div class="form-group">
                            <label [translate]="'ANEXAR_ARQUIVO'"></label>
                        </div>
                    </div>
                </div>
                <div class="row" appDnd (fileDropped)="onFileDropped($event)" style="margin-top: -24px">
                    <div class="col-5 mb-4" *ngFor="let file of fileSelected; let i = index">
                        <button id="btn-excluir-anexo-tarefa" type="button" class="btn btn-sm-rounded-milvus bg-danger"
                            style="position: absolute; right: 14px; top: -13px; color: white"
                            data-dismiss="modal" aria-label="Close" (click)="excluirAnexo(i)">
                            <i class="fas fa-times"></i>
                        </button>
                        <div class="preview-upload-file">
                            <div class="div-img-anexo" *ngIf="file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml' || file.type === 'png'">
                                <img class="mt-4" [src]="file.url || file.url.changingThisBreaksApplicationSecurity" style="width: 100px;">
                            </div>
                            <audio controls class="mt-4" *ngIf="file.type === 'audio/mp3'" style="width: 240px;">
                                <source [src]="file.url" type="audio/mpeg">
                            </audio>
                            <video controls class="mt-3" style="justify-self: center;" *ngIf="file.type === 'video/mp4'" width="166" height="102">
                                <source [src]="file.url" type="video/mp4">
                            </video>
                            <i *ngIf="file.type === 'application/pdf'" class="far fa-file-pdf icon-anexo mt-4"></i>
                            <i *ngIf="file.type === 'application/zip'" class="far fa-file-archive icon-anexo mt-4"></i>
                            <i *ngIf="file.type === 'application/vnd.ms-excel'" class="far fa-file-excel icon-anexo mt-4"></i>
                            <i *ngIf="file.type !== 'image/jpeg' && file.type !== 'png' && file.type !== 'image/png' && file.type !== 'image/svg+xml' && file.type !== 'application/pdf' && file.type !== 'application/zip' && file.type !== 'application/vnd.ms-excel' && file.type !== 'audio/mp3' && file.type !== 'video/mp4'" class="far fa-file icon-anexo mt-4"></i>
                            <span style="margin-top: 0 !important; font-size: 13px; margin-left: 15px;" [ngbTooltip]="file.name">{{ file.name | ellipsis: 50 }}</span>
                        </div>
                        <button id="btn-download-anexo" type="button" class="btn btn-sm-rounded-milvus btn-light"
                            style="position: absolute; left: 20px; top: 135px; color: black"
                            data-dismiss="modal" aria-label="Close" (click)="downloadAnexo(file)"
                            *ngIf="file && file.url && file.url.changingThisBreaksApplicationSecurity">
                            <i class="fa fa-download"></i>
                        </button>
                    </div>
                    <div class="col-5">
                        <div id="input-file-area-tarefa" class="inputFileArea" (click)="triggerFileInput()"
                            [ngbTooltip]="tooltipAttachment"
                            tooltipClass="tooltip_attachment">
                            <div class="anexo-label">
                                <i _ngcontent-yvq-c100="" class="fas fa-paperclip fa-3x icon-chat-opt ng-star-inserted" style="margin-bottom: 20px;"></i>
                                <span [translate]="'CLIQUE_ARRASTE_ARQUIVO'"></span>
                            </div>
                        </div>
                        <input id="fileInputTarefa" type="file" accept="{{uploadFileType}}" (change)="changeFile($event.target.files)" multiple>
                    </div>
                </div>
            </div>
            <div *ngIf="visualizacao === true" class="col-8" style="border-right: 1px solid #cfd1d3;">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label [translate]="'TITULO'"></label>                            
                            <br>
                            <span>{{tarefa.titulo}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label [translate]="'OPERADOR'"></label>
                            <br>
                            <span>{{tarefa.tecnico_id.text}}</span>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label [translate]="'TIPO_TAREFA'"></label>
                            <br>
                            <span>{{tarefa.tipo_tarefa_id.text}}</span>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label [translate]="'AGENDAMENTO'"></label>
                            <br>
                            <span>{{tarefa.data_agendamento | date:'dd/MM/yyyy HH:mm'}}</span>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label [translate]="'LIMITE'"></label>
                            <br>
                            <span>{{tarefa.data_limite | date:'dd/MM/yyyy HH:mm'}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label [translate]="'CLIENTE'"></label>
                            <br>
                            <span>{{tarefa.cliente_id.text}}</span>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label [translate]="'RELACIONAR_CHAMADO'"></label>
                            <br>
                            <span>{{tarefa.chamado_id.text}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label [translate]="'DESCRICAO'"></label>
                            <br>
                            <span [innerHTML]="tarefa.descricao"></span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label [translate]="'ARQUIVOS_ANEXADOS'"></label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 mb-4" *ngFor="let file of fileSelected; let i = index">
                        <div class="preview-upload-file">
                            <div class="div-img-anexo" *ngIf="file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml' || file.type === 'png'">
                                <img class="mt-4" [src]="file.url" style="width: 100px;">
                            </div>
                            <audio controls class="mt-4" *ngIf="file.type === 'audio/mp3'" style="width: 240px;">
                                <source [src]="file.url" type="audio/mpeg">
                            </audio>
                            <video controls class="mt-3" style="justify-self: center;" *ngIf="file.type === 'video/mp4'" width="166" height="102">
                                <source [src]="file.url" type="video/mp4">
                            </video>
                            <i *ngIf="file.type === 'application/pdf'" class="far fa-file-pdf icon-anexo mt-4"></i>
                            <i *ngIf="file.type === 'application/zip'" class="far fa-file-archive icon-anexo mt-4"></i>
                            <i *ngIf="file.type === 'application/vnd.ms-excel'" class="far fa-file-excel icon-anexo mt-4"></i>
                            <i *ngIf="file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/svg+xml' && file.type !== 'application/pdf' && file.type !== 'application/zip' && file.type !== 'application/vnd.ms-excel' && file.type !== 'audio/mp3' && file.type !== 'video/mp4' && file.type !== 'png'" class="far fa-file icon-anexo mt-4"></i>
                            <span class="mt-4">{{file.name}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">                
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label [translate]="'CLIENTE_'"></label>
                            <select-padrao id="select-cliente-tarefa" [entidade]="'selectClientes'" [returnObj]="true" [model]="tarefa.cliente_id" [customItem]="tarefa.cliente_id" (selectChange)="setClienteId($event)" [disabled]="checkIfDisableCliente()" ></select-padrao>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label [translate]="'RELACIONAR_CHAMADO'"></label>
                            <select-padrao id="select-relacionar-chamado-tarefa" [entidade]="'selectChamadosRelacionados'" [model]="tarefa.chamado_id" [params]="tarefa.cliente_id && tarefa.cliente_id.id ? tarefa.cliente_id : null" [returnObj]="true" [disabled]="checkIfDisableRelacionarChamado()" (selectChange)="setChamdoId($event); listaTarefas($event)"></select-padrao>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div *ngIf="tarefa.tecnico_id === '' || tarefa.tecnico_id === null" class="row">
                            <div class="col-12">
                                <h4 [translate]="'SELECIONE_OPERADOR_MOSTRAR_TAREFAS_ASSOCIADAS'"></h4>
                            </div>
                        </div>
                        <div *ngIf="tarefa.tecnico_id !== '' && tarefa.tecnico_id !== null" class="row">
                            <div *ngIf="tarefasAssociadasTecnico.length > 0" class="col-12"> 
                                <div class="table-responsive table-no-shadow table-head-normal" style="overflow-x: hidden;">
                                    <table class="table align-items-center table-flush">
                                        <thead>
                                            <tr>
                                                <th colspan="2">
                                                    <div class="d-flex align-items-center">
                                                        <img *ngIf="tarefa.tecnico_id.foto" [src]="tarefa.tecnico_id.foto" style="width: 30px; border-radius: 22px; margin-right: 10px;">
                                                        <span *ngIf="!tarefa.tecnico_id.foto" class="tecnico-sem-foto">{{splitName(tarefa.tecnico_id.text)}}</span>
                                                        <span>{{'TAREFAS_ASSOCIADAS_AO_TECNICO' | translate}} {{tarefa.tecnico_id.text}}</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let tarefaAssociada of tarefasAssociadasTecnico">
                                                <td><span [ngbTooltip]="checkIfHasTitle(tarefaAssociada)">{{checkIfHasTitle(tarefaAssociada) | ellipsis:15}}</span></td>
                                                <td class="text-right">{{tarefaAssociada.data_agendamento | date: 'dd/MM/yyyy HH:mm'}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot *ngIf="tarefasAssociadasTecnico.length > 0">
                                            <tr>
                                                <td colspan="2" style="font-size: 16px; padding: 0 14px 0 18px;">
                                                    <pagination [meta]="paginacaoTarefas" [mini]="true" (pageChange)="paginarListagem($event)"></pagination>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>                                
                            </div>
                            <div *ngIf="tarefasAssociadasTecnico.length === 0" class="col-12">
                                <h4 [translate]="'NENHUMA_TAREFA_ASSOCIADA_OPERADOR'"></h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <div *ngIf="tarefa.chamado_id === '' || tarefa.chamado_id === null" class="row">
                            <div class="col-12">
                                <h4 [translate]="'RELACIONE_TICKET_TAREFAS ASSOCIADAS'"></h4>
                            </div>
                        </div>
                        <div *ngIf="tarefa.chamado_id !== '' && tarefa.chamado_id !== null" class="row">
                            <div class="col-12">
                                <h4 *ngIf="listagemTarefas.length === 0">{{'NENHUMA_TAREFA_ASSOCIADA_TICKET' | translate}} {{tarefa.chamado_id.text}}</h4>
                                <h4 *ngIf="listagemTarefas.length > 0">{{'TAREFAS_ASSOCIADAS_TICKET' | translate}} {{tarefa.chamado_id.text}}</h4>
                            </div>
                        </div>
                        <div *ngIf="listagemTarefas.length > 0" class="row">
                            <div class="col-12">
                                <div class="table-responsive table-no-shadow table-head-normal" style="overflow-x: hidden;">
                                    <table class="table align-items-center table-flush">
                                        <thead>
                                            <tr>
                                                <th class="w-1"></th>
                                                <th [translate]="'DESCRICAO'"></th>
                                                <th class="text-right" [translate]="'AGENDADO'"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let tarefa of listagemTarefas">
                                                <td>
                                                    <span *ngIf="tarefa.foto_tecnico === null" class="tecnico-tarefa-sem-foto" [ngbTooltip]="tarefa.nome_tecnico">{{splitName(tarefa.nome_tecnico)}}</span>
                                                    <span *ngIf="tarefa.foto_tecnico !== null"><img src="{{tarefa.foto_tecnico}}" class="tecnico-tarefa-com-foto" [ngbTooltip]="tarefa.nome_tecnico"></span>                                                    
                                                </td>
                                                <td style="max-width: 50px; white-space: normal;"><span [ngbTooltip]="checkIfHasTitle(tarefa)">{{checkIfHasTitle(tarefa) | ellipsis:15}}</span></td>
                                                <td class="text-right">{{tarefa.data_agendamento | date:'dd/MM/YYYY HH:mm'}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot *ngIf="listagemTarefas.length > 0">
                                            <tr>
                                                <td colspan="8" style="font-size: 16px; padding: 0 14px 0 18px;">
                                                    <pagination [meta]="paginacaoAssociadas" [mini]="true" (pageChange)="paginarAssociadas($event)"></pagination>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- footer -->
    <div class="modal-footer">
        <div class="font-12" *ngIf="chamadoData" style="position: absolute; left: 25px;">
            <span [translate]="'CRIADO_POR'" class="mr-1"></span>
            <span class="mr-1"><b>{{tarefa.tecnico_id.text}}</b></span>
            <span [translate]="'EM'" *ngIf="tarefa.data_criacao" class="mr-1"></span>
            <span>{{tarefa.data_criacao | date:'dd/MM/YYYY HH:mm'}}</span>
        </div>
        <div *ngIf="visualizacao === false">
            <button id="btn-finalizar-tarefa" *ngIf="(create === false && tarefa.status_id !== 4)" type="button" class="btn btn-default badge-pill" [translate]="'FINALIZAR'" (click)="finalizarTarefa(tarefa.tarefa_id)" [disabled]="salvando"></button>
            <button id="btn-abrir-modal-agendar-tarefa" *ngIf="(create === false && tarefa.status_id !== 4)" type="button" class="btn btn-info-2 badge-pill" [translate]="'REAGENDAR'" [disabled]="verificaSeDesativa() || salvando" (click)="abrirModalAgendarTarefa()"></button>        
            <button id="btn-abrir-modal-reagendar-tarefa" *ngIf="create === false" type="button" class="btn btn-sm btn-danger small-circled-btn text-17" (click)="deleteTarefa()"  ><i class="far fa-trash-alt"></i></button>
            <button id="btn-salvar-tarefa" type="button" class="btn btn-success badge-pill" (click)="salvarTarefa()" [disabled]="verificaSeDesativa() || salvando">
              <i class="fas fa-save"></i>
              <span [translate]="'SALVAR'"></span>
            </button>
        </div>
    </div>
</div>

<ng-template #tooltipAttachment>
  <div class="d-grid">
    <span [translate]="'ANEXO_MAXIMO_ARQUIVOS'"></span>
    <span [translate]="'ANEXO_TAMANHO_ARQUIVO'"></span>
    <span [translate]="'ANEXO_ARQUIVOS_SUPORTADOS'"></span>
  </div>
</ng-template>