import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TokenService } from 'src/app/core/token/token.service';
import { FiltroAvancadoService } from 'src/app/services/filtroavancado.service';
import { environment } from 'src/environments/environment';
import { CondicaoFiltroAvancadoComponent } from '../condicao-filtro-avancado/condicao-filtro-avancado.component';

@Component({
  selector: 'app-modal-editar-filtro-avancado',
  templateUrl: './modal-editar-filtro-avancado.component.html',
  styleUrls: ['./modal-editar-filtro-avancado.component.scss']
})
export class ModalEditarFiltroAvancadoComponent implements OnInit, OnDestroy {

  @Input() filtro_avancado;
  filtro = []
  payload = {
    _id: '',
    nome_filtro: '',
    is_favorito: false,
    filtro: {
    }
  }


  selector: string;
  newSubs: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    private filtroAvancadoService: FiltroAvancadoService,
    private toastr: ToastrService,
    private tokenService: TokenService,
    private modalService: NgbModal,
    private elemRef: ElementRef,
  ) {
    this.selector = this.elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    this.obterFiltro();
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  obterFiltro(): void {
    this.newSubs = this.filtroAvancadoService.obterFiltro(this.filtro_avancado.id).subscribe(
      (result) => {
        if(result.length) {
          result.forEach(res => {
            for (var prop in res) {
              if (prop !== 'nome_filtro' && prop !== 'is_favorito') {
                if (prop.includes('personalizado')) {
                  for (let i = 0; i < res.personalizado.length; i++) {
                    const element = res.personalizado[i];
                    this.payload.filtro[prop + '-' + element.id] = element.id
                    this.filtro.push({ campo: { id: element.id, text: element.text, atributo: prop }, valor: element.valor })
                  }
                } else {
                  this.payload.filtro[prop] = res[prop];

                  if(prop === 'categorias') {
                    this.filtro.push({ campo: {id: Number(res[prop].ids.split('/')[0]), text: result[prop].text, atributo: prop}, valor: { id: Number(res[prop].ids.split('/')[1]), text: res[prop].labels, primary:  Number(res[prop].ids.split('/')[0])} });
                  } else {
                    this.filtro.push({ campo: { id: res[prop].id ? res[prop].id : null, text: result[prop].label, atributo: prop }, valor: { text: res[prop].text ? res[prop].text : res[prop], id: res[prop].id ? res[prop].id : '' } });
                  }
                }
              }
            }
          });
        } else {
          for (var prop in result) {
            if (prop !== 'nome_filtro' && prop !== 'is_favorito') {
              if (prop.includes('personalizado')) {
                for (let i = 0; i < result.personalizado.length; i++) {
                  const element = result.personalizado[i];
                  this.payload.filtro[prop + '-' + element.id] = element.id
                  this.filtro.push({ campo: { id: element.id, text: element.text, atributo: prop }, valor: element.valor })
                }
              } else {
                this.payload.filtro[prop] = result[prop];
                this.filtro.push(
                  { campo: { 
                    id: result[prop].id ? result[prop].id : null, 
                    text: result[prop].label,
                    atributo: result[prop].atributo
                  }, valor: { text: result[prop].text } }
                  );
              }
              console.log(this.payload)
            }
          }
        }     
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      }
    )
  }

  ajustaData(data) {
    const _aux = new Date(data)
    return _aux.getDay().toString().padStart(2, '0') + '-' + _aux.getMonth().toString().padStart(2, '0') + "-" + _aux.getFullYear()
  }

  verificaCampos(item: any) {
    if (item.campo.atributo.includes('data')) {    
      if (typeof item.valor.text === 'string' && item.valor.text.includes('-')) {
        return this.ajustaData(item.valor.text)
      } else {
        return item.valor.text ? item.valor.text : item.valor
      } 
    } else {
      return item.valor.text ? item.valor.text : item.valor
    }     
  }

  change(item) {
    const datepipe: DatePipe = new DatePipe('en-US')
    return Array.isArray(item.valor) ? datepipe.transform(item.valor[0], 'dd-MM-YYYY') + ' | ' + datepipe.transform(item.valor[1], 'dd-MM-YYYY') :item.valor === "true" ? this.translate.instant('SIM') : item.valor === "false" ? this.translate.instant('NAO') : this.verificaCampos(item);
  }

  close(result?) {
    this.activeModal.close();
  }

  salvar(condicao?) {
    this.payload._id = this.filtro_avancado.id
    this.payload.nome_filtro = this.filtro_avancado.nome_filtro;
    this.payload.is_favorito = this.filtro_avancado.is_favorito;
    this.payload.filtro = {}
    
    for (let i = 0; i < this.filtro.length; i++) {
      let element = this.filtro[i];
      if (element.campo.atributo === 'data_criacao') {
        element.campo.atributo = 'filtro_data_criacao';
      }

      if (element.campo.atributo === 'data_agendamento') {
        element.campo.atributo = 'filtro_data_agendamento';
      }
      
      if (!element.campo.atributo.includes('personalizado')) {
        if (Array.isArray(element.campo.atributo)) {
          for (let i = 0; i < element.campo.atributo.length; i++) {
            element.valor[i] = element.valor[i];
            this.payload.filtro[element.campo.atributo[i]] = element.valor[i];
            this.payload.filtro[element.campo.atributo[i]].label = element.campo.text;
          }
        } else {  
          if(element.campo.atributo === 'categorias') {
            this.payload.filtro['categoria_primaria'] = element.valor.ids.split('/')[1] ?  Number(element.valor.ids.split('/')[0].trim()) : '';
            this.payload.filtro['categoria_secundaria'] = element.valor.ids.split('/')[1] ? Number(element.valor.ids.split('/')[1].trim()) : element.valor.ids.split('/')[0] ?  Number(element.valor.ids.split('/')[0].trim()) : '';
            this.payload.filtro[element.campo.atributo].label = element.campo.text;
            this.payload.filtro[element.campo.atributo].atributo = element.campo.atributo;
          } else if (element.campo.atributo === 'cliente') {
            element.valor.id = element.valor.id ? parseInt(element.valor.id) : parseInt(element.campo.id);
            this.payload.filtro[element.campo.atributo] = {}
            this.payload.filtro[element.campo.atributo] = element.valor;
            this.payload.filtro[element.campo.atributo].label = element.campo.text;
            this.payload.filtro[element.campo.atributo].atributo = element.campo.atributo;
          } else {
            element.valor.id = element.valor.id ? parseInt(element.valor.id) : parseInt(element.campo.id);
            this.payload.filtro[element.campo.atributo] = element.valor
            this.payload.filtro[element.campo.atributo].label = element.campo.text;
            this.payload.filtro[element.campo.atributo].atributo = element.campo.atributo;
          }
        }
      } else {
        if(element.campo.tipo && (element.campo.tipo === 'data' || element.campo.tipo === 'data_periodo')) {
          this.payload.filtro['personalizado-' + element.campo.id] = element.valor.map(date => { return this.tokenService.convertDateWithoutTime(date) });
        } else {
          this.payload.filtro['personalizado-' + element.campo.id] = element.valor;
        }
      }
    }

    this.newSubs = this.filtroAvancadoService.atualizarFiltro(this.payload).subscribe(
      (result) => {
        this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
        this.filtro = [];
        this.obterFiltro();
        if(!condicao) {
          this.close();
        }
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      }
    )
  }

  verificaCampo(lista: Array<any>, result) {
    return lista.findIndex(x => 
      (x.campo.atributo === result.campo.atributo) && (x.campo.text.toLowerCase() === result.campo.text.toLowerCase())
      || x.campo.atributo === 'categorias' && (result.campo.atributo === 'categoria_primaria' || result.campo.atributo === 'categoria_secundaria') 
    );
  }

  openCondicao() {
    const modalRef = this.modalService.open(CondicaoFiltroAvancadoComponent, {
      backdrop: "static",
      windowClass: 'modal-custom-milvus',
      keyboard: true,
      scrollable: false,
      size: "lg",
    });

    modalRef.componentInstance.filtroLista = this.filtro;

    modalRef.result.then((result) => {
      if (result && result.campo) {
        if (Array.isArray(result.campo.atributo)) {
          for (let i = 0; i < result.campo.atributo.length; i++) {
            result.valor[i] = this.tokenService.convertDateWithoutTime(result.valor[i])
            this.filtro_avancado.filtro[result.campo.atributo[i]] = this.tokenService.convertDateWithoutTime(result.valor[i])
          }
          
          this.filtro.push(result)
        } else {
          if (this.verificaCampo(this.filtro, result) > -1) {  
            if (result.campo.chave === '@Categorias') {
              this.payload.filtro['categoria_primaria'] = Number(result.valor.primary);
              this.payload.filtro['categoria_secundaria'] = Number(result.valor.id);              
              this.filtro[this.verificaCampo(this.filtro, result)].valor = result.valor;
            } else {
              this.filtro[this.verificaCampo(this.filtro, result)] = result;
            }
          } else if (result.campo.chave === '@Categorias') {
            this.payload.filtro['categoria_primaria'] = Number(result.valor.primary);
            this.payload.filtro['categoria_secundaria'] = Number(result.valor.id);
            this.filtro.push(result);
          } else {
            this.payload.filtro[result.campo.atributo] = result.valor.id;
            this.filtro.push(result);
          }
        }

        this.salvar(true);
      }
    });
  }

  valid() {
    return this.filtro_avancado.nome_filtro == '' || this.filtro.length < 1
  }

  removeItem(pos) {
    this.filtro.splice(pos, 1);
  }
}
