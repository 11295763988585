<div class="modal-content modal-milvus">
  <div class="modal-header-milvus align-items-center">
      <div class="col-6 header-col">
          <span class="modal-title" id="modal-title-default">{{'TRANSFERIR_CHAT' | translate}}</span>
      </div>
      <div class="col-6 header-col">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
              <i class="mdi mdi-close"></i>
            </button>
      </div>
  </div>

  <div class="modal-body">
      <div clas="row mt-3">
          <div class="col-12">
            <label>
                {{ 'TRANSFERIR_PARA' | translate }}
            </label>
          </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
            <label class="radio-box">
                <div class="col d-flex justify-content-start">
                    <input type="radio" name="padrao_todos" id="liberado" [(ngModel)]="tipoTransferencia" [value]="1" />
                    <span class="checkmark"></span>
                </div>
            </label>
            <label style="font-size: 12px; font-weight: normal; margin-right: 10px;"
                [translate]="'FILA_ATENDIMENTO'"></label>
        </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <label class="radio-box">
                    <div class="col d-flex justify-content-start">
                        <input type="radio" name="padrao_todos" id="bloqueado_somente_leitura" [(ngModel)]="tipoTransferencia" [value]="2" />
                        <span class="checkmark"></span>
                    </div>
                </label>
                    <label style="font-size: 12px; font-weight: normal;" [translate]="'OPERADOR'"></label>
                </div>
            </div>
            <div class="row mt-3" *ngIf="tipoTransferencia === 1">
                <div class="col-12">
                    <select-padrao [entidade]="'getFilasChat'" [params]="chatData" [model]="fila"  [returnObj]="true" (selectChange)="changeFila($event)"></select-padrao>
                </div>
            </div>
            <div class="row" *ngIf="tipoTransferencia === 2">
                <div class="col-12">
                    <!-- <select-padrao [entidade]="'getTecnicosOnOff'" [model]="tecnico"  [returnObj]="true" (selectChange)="changeTecnico($event)"></select-padrao> -->
                <select-tecnico [entidade]="'getTecnicosOnOff'" [model]="tecnico"  [returnObj]="true" (selectChange)="changeTecnico($event)"></select-tecnico>
                </div>
            </div>
        </div>



        <div class="modal-footer modal-footer-milvus">
            <button class="btn badge-pill btn-mv-primary" (click)="transferirChat()">
                <span [translate]="'TRANSFERIR'"></span>
            </button>
        </div>
    </div>