import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
    providedIn: 'root'
})
export class AntivirusService {
    private apiUrl = environment.apiGestorUrlLocal;
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() }),
        params: {}
    };

    constructor(private http: HttpClient, private tokenService: TokenService) { }

    getBuscaPersonalizado(params, filtros): Observable<any> {
        this.httpOptions.params = params;
        return this.http.post(`${this.apiUrl}api/antivirus/personalizado/lista`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    getBuscaPadrao(params, filtros): Observable<any> {
        this.httpOptions.params = params;
        return this.http.post(`${this.apiUrl}api/antivirus/padrao/lista`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    getAntivirus(filtro): Observable<any> {
        this.httpOptions.params = "";
        return this.http.get(`${this.apiUrl}api/antivirus/dropdown/${filtro}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    setAntivirusPadrao(payload): Observable<any> {
        this.httpOptions.params = "";
        return this.http.post(`${this.apiUrl}api/antivirus/padrao`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    setAntivirusPersonalizado(payload): Observable<any> {
        this.httpOptions.params = "";
        return this.http.post(`${this.apiUrl}api/antivirus/personalizado`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    deleteAntivirusPadrao(id): Observable<any> {
        this.httpOptions.params = "";
        return this.http.delete(`${this.apiUrl}api/antivirus/padrao/` + id, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    deleteAntivirusPersonalizado(id): Observable<any> {
        this.httpOptions.params = "";
        return this.http.delete(`${this.apiUrl}api/antivirus/personalizado/` + id, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    updateAntivirusPadrao(payload): Observable<any> {
        this.httpOptions.params = "";
        return this.http.put(`${this.apiUrl}api/antivirus/padrao`, payload, this.httpOptions)
        .pipe(
            catchError(this.handleError)
        );
    }

    updateAntivirusPersonalizado(payload): Observable<any> {
        this.httpOptions.params = "";
        return this.http.put(`${this.apiUrl}api/antivirus/personalizado`, payload, this.httpOptions)
        .pipe(
            catchError(this.handleError)
        );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Ops! Ocorreu um erro:', error.error.message);
        } else {
            console.error(
                `Backend retornou o código ${error.status}, ` +
                `o body é: ${error.error}`);
        }
        return throwError(
            error.error);
    }

}
