import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';
import { IConfigCamposFinalizacaoRequest } from '../interfaces/finalizacao-ticket/finalizacao-ticket.interface';

@Injectable({
  providedIn: 'root'
})
export class CamposCustomizaveisService {

  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() }),
    params: {}
  };

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  listarCamposSistema(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamado-campo-sistema/listar/campos`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  listaCamposPersonalizados(params?, filtros?): Observable<any> {
    this.httpOptions.params = (params) ? params : "";
    return this.http.post(`${this.apiUrl}api/campos-personalizados/listar`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirCampoPersonalizado(campo_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/campos-personalizados/${campo_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  criarCampoPersonalizado(campo): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/campos-personalizados/`, campo, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizarCampoPersonalizado(campo, campo_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/campos-personalizados/${campo_id}`, campo, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  buscarCampoPersonalizado(campo_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/campos-personalizados/${campo_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  buscarCamposCriacao(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamado-campo-sistema/buscar/criacao`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  buscarCamposPortalTecnicoPadrao(chamado_id?: any): Observable<any> {    
    this.httpOptions.params = chamado_id ? {chamado_id: chamado_id} : "";
    
    return this.http.get(`${this.apiUrl}api/chamados/formulario/campos-portal/tecnico-padrao`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  buscarCamposEdicaoFinalizacao(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamado-campo-sistema/buscar/finalizacao`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  salvarConfigCamposSistema(campos): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamado-campo-sistema/salvar`, campos, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  buscarCamposCustomizaveisEmpresa(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/campos-personalizados/empresa`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  buscarCamposCustomizaveisChamado(chamado_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/campos-personalizados/chamado/${chamado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /* campoPersonalizadoListaOpcao */
  campoPersonalizadoListaOpcaoCriar(campo): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/campos-personalizados/lista/opcoes`, campo, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  campoPersonalizadoListaOpcaoAtualizar(campo): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/campos-personalizados/lista/opcoes`, campo, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  campoPersonalizadoListaOpcaoDeletar(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/campos-personalizados/lista/opcoes/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  campoPersonalizadoListaOpcaoObtem(id) : Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/campos-personalizados/lista/opcoes/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  campoPersonalizadoListaOpcaoObtemTodas(campo_personalizado) : Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/campos-personalizados/lista/opcoes/todas/${campo_personalizado}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getFinalizationFields(): Observable<IConfigCamposFinalizacaoRequest> {
    this.httpOptions.params = "";
    return this.http.get<IConfigCamposFinalizacaoRequest>(`${this.apiUrl}api/chamado-campo-sistema/listar/grupos-campos`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  saveFinalizationFieldsConfig(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamado-campo-sistema/salvar-grupo`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropdownTipos(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/campos-personalizados/dropdown/tipos`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  campoPersonalizadoListaOpcaoObtemTodasPaginadas(params, campo_personalizado_id): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/campos-personalizados/lista/opcoes/todas-paginada/${campo_personalizado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  criarListaComOpcoes(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/campos-personalizados/criar-lista-com-opcoes`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  validacaoEspecifica(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/campos-personalizados/validacao/especificas`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropDownCamposRegra(tipo_pesquisa): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/gatilho-campos/dropdown-campos/${tipo_pesquisa}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterInformacoesRegra(chave): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/gatilho-campos/obter-informacoes/${chave}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionarGatilhoRegra(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/gatilho-campos`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterGatilhoRegra(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/gatilho-campos/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirGatilhoRegra(payload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() }),
      params: {},
      body: payload
    };
    return this.http.delete(`${this.apiUrl}api/gatilho-campos`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizarGatilhoRegra(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/gatilho-campos`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  listagemGatilhosRegra(params, filtro): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/gatilho-campos/listagem`, filtro, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  listarAcoes(params, gatilho_id): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/gatilho-campos/campo-acao/listagem/${gatilho_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirAcao(payload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() }),
      params: {},
      body: payload
    };
    return this.http.delete(`${this.apiUrl}api/gatilho-campos/campo-acao`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionarAcao(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/gatilho-campos/campo-acao`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizarStatusGatilho(payload) : Observable<any> {
    this.httpOptions.params = '';
    return this.http.patch(`${this.apiUrl}api/gatilho-campos`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  } 

  validacaoGatilho(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/gatilho-campos/validacao`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
