import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class ChamadoProgramadoService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
    params: {}
  };

  constructor(private tokenService: TokenService, private http: HttpClient) { }

  uploadImagem(file): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/base-conhecimento/adicionaArquivo`, file,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  getPeriodoProgramado(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamado-programado/dropdown/tipo-periodo`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDiaProgramado(params): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamado-programado/dropdown/dia-periodo/${params}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getRepeticaoProgramado(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamado-programado/dropdown/tipo-repeticao`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTipoTicket(params?): Observable<any> {
    if(params) {
      this.httpOptions.params = params;
    } else {
      this.httpOptions.params = {is_ativo: "__all__"};
    }
    return this.http.get(`${this.apiUrl}api/chamadoTipo/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getFrequenciaProgramado(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamado-programado/dropdown/tipo-frequencia`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  criarChamadoProgramado(chamado): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamado-programado`, chamado, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizarChamadoProgramado(chamado): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/chamado-programado`, chamado, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deletarChamadoProgramado(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/chamado-programado/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  duplicarChamadoProgramado(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamado-programado/duplicar/${id}`, '', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
