import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';
import { IMostraOSTicket } from '../interfaces/finalizacao-ticket/finalizacao-ticket.interface';

@Injectable({
  providedIn: 'root'
})
export class ChamadosService {
  private apiUrl = environment.apiGestorUrlLocal;
  private apiUrlNova = environment.apiGestorUrlNova;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
    params: {}
  };

  constructor(private tokenService: TokenService, private http: HttpClient) { }

  aprovarTicket(payload): Observable<any> {
    this.httpOptions.params = payload;
    return this.http.post(`${this.apiUrl}api/chamados/aprovacao/aprovar`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  reprovarTicket(payload): Observable<any>{
    this.httpOptions.params = payload;
    return this.http.post(`${this.apiUrl}api/chamados/aprovacao/reprovar`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterPrecosClienteSemContrato(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/configuracao-contrato/sem-contrato`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizarAcompanhamento(id, payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/chamadolog/acompanhamento/${id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      ) 
  }

  excluirLog(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.delete(`${this.apiUrl}api/chamadolog/`+id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      ) 
  }

  getChamadoList(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/chamados/listagem`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getChamadoKanban(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/chamados/board`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPrioridades(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/chamados/prioridade/dropdown`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  desvincularPai(chamado_id, payload) : Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/chamados/desvincular-pai/${chamado_id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      ) 
  }

  getStatusSla(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/sla-chamado/status/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirTicket(id): Observable<any>{
    this.httpOptions.params = '';
    return this.http.delete(`${this.apiUrl}api/chamados/`+id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  recalcularSLA(ids): Observable<any>{
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/chamados/recalcular-sla`,ids, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  getAtualizacaoMassa(): Observable<any>{
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/chamados/atualizacao-massa/dropdown`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  atualizarChamadosMassa(chamado): Observable<any>{
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/chamados/atualizacao-massa`, chamado, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  atualizarChamadosPlay(chamado): Observable<any>{
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/chamados/play`, chamado, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  atualizarChamadosPause(chamado): Observable<any>{
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/chamados/pause`, chamado, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  getCategorias(params): Observable<any>{
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/categoriaprimaria/dropdown/buscar-arvore/`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  getLogs(id: number, params: any): Observable<any>{
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/chamadolog/chamado/`+id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  validaAberturaChamado(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamados/validar-abertura-contrato/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  ObterInadimplenciaChamado(cliente_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamados/obter-inadimplencia/${cliente_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  criarChamado(chamado): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamados`, chamado, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateChamado(chamado): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/chamados`, chamado, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirChamado(chamado_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/chamados/${chamado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  reabrirChamado(chamado_id, motivo): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/chamados/reabrir/${chamado_id}`, motivo, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadAnexoChamado(formData): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamados/anexo`, formData, {headers: new HttpHeaders({'authorization': this.tokenService.getToken()})})
      .pipe(
        catchError(this.handleError)
      );
  }

  getdadosUltimoAtendimento(id: number): Observable<any>{
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamados/atendimento/ultimo/`+id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  getDropdownStatusChamado(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamados/filtro/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getChamadoById(chamado_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamados/contador/${chamado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getChamadoByIdNovo(chamado_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrlNova}manager/tickets/${chamado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterSeguidoresChamado(chamado_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamados/seguidoreschamado/${chamado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  seguirChamado(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamadoSeguir/seguir`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deixarSeguirChamado(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamadoSeguir/desseguir`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterUltimosChamadosCliente(cliente_id, total, chamado_id?): Observable<any> {
    this.httpOptions.params = {
      cliente_id: cliente_id,
      total: total,
      chamado_id: (chamado_id) ? chamado_id : ''
    };
    return this.http.get(`${this.apiUrl}api/chamados/cliente/ultimos`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  alterarPrivacidadeComentario(chamado_id, log_id, privacidade): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/chamadolog/privacidade/${chamado_id}/${log_id}`, privacidade, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deletarAnexoChamado(anexo_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/chamados/anexo/${anexo_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  criarLog(log): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamadolog`, log, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  carregarAnexoChamado(formData): Observable<any> {
    return this.http.post(`${this.apiUrl}api/chamados/anexo`, formData, {headers: new HttpHeaders({'authorization': this.tokenService.getToken()})})
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownAnexosChamado(chamado_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamados/anexo/dropdown/${chamado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  enviarEmailChamado(email): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamados/envio-email`, email, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  enviarEmailChamadoNovo(email): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamados/envio-email/novo`, email, {headers: new HttpHeaders({'authorization': this.tokenService.getToken()})})
      .pipe(
        catchError(this.handleError)
      );
  }

  salvarChecklistChamado(checklist): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamados/salvar-ckecklist`, checklist, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownProdutosPecas(params?): Observable<any> {
    this.httpOptions.params = params ? params: '';
    return this.http.get(`${this.apiUrl}api/produtos/buscar`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPecasChamado(chamado_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamado-pecas/${chamado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizaPecasChamado(pecas_id, pecas): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/chamado-pecas/${pecas_id}`, pecas, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  criarPecasChamado(pecas): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamado-pecas`, pecas, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deletarPecasChamado(pecas_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/chamado-pecas/${pecas_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterValoresHorasChamado(chamado_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamados/atendimento/valores/${chamado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterDadosAtendimento(chamado_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamados/atendimento/${chamado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  alterarDadosAtendimento(horas): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/chamados/atendimento/${horas.id}`, horas, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  buscarHorasTrabalhadas(chamado_id, params?): Observable<any> {
    this.httpOptions.params = params ? params : "";
    return this.http.get(`${this.apiUrl}api/chamadolog/listarHorasApontadas/${chamado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  buscarListaHorasTrabalhadas(chamado_id, params, payload?): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/chamadolog/listagem-atendimentos/${chamado_id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirHorasApontadas(horas_id, parametro): Observable<any> {
    this.httpOptions.params = parametro;
    return this.http.delete(`${this.apiUrl}api/chamados/excluir/horas-apontadas/${horas_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getOrdemServico(chamado_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamados/ordemServico/${chamado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getOrdemServicopdf(chamado_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamados/ordemServicopdf/${chamado_id}`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      responseType: 'blob'
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  enviarOsEmail(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/emailSend/ordemservico`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  calculaHorasAtendimento(chamado_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamados/calculaHorasAtendimento/${chamado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateAgendamento(agendamento): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/chamados/agendamento`, agendamento, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownChamadosConciliados(cliente_id, chamado_id, filtro?, pagina?): Observable<any> {
    if(filtro) {
      this.httpOptions.params = {chamado_id: chamado_id, filtro: filtro, total_registros: 10, pagina: pagina ? pagina : 1};
    } else {
      this.httpOptions.params = {chamado_id: chamado_id, total_registros: 10, pagina: pagina ? pagina : 1};
    }
    return this.http.get(`${this.apiUrl}api/chamadoConciliado/dropDownChamados/${cliente_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownChamadosConciliadosSimplified(cliente_id): Observable<any> {
    return this.http.get(`${this.apiUrl}api/chamadoConciliado/dropDownChamados/${cliente_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  conciliarChamado(chamadoConciliado): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamadoConciliado/conciliarChamado`, chamadoConciliado, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  desconciliarChamado(chamadoConciliado): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamadoConciliado/desconciliarChamado`, chamadoConciliado, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  avaliarChamado(chamado_id, avaliacao): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamado-avaliacao-interno/avaliar/${chamado_id}`, avaliacao, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getByIdContador(chamado_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamados/contador/${chamado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterLocalizacaoTecnicos(filtros): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/usuario-geolocalizacao/obterGeolocalizacoes`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  pausarSLA(sla): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/sla-chamado/pausar`, sla, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }
  
  adicionarContatoChamado(contatos): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamados/contato/adicionar`, contatos, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  removerContatoChamado(contato): Observable<any> {
    this.httpOptions.params = "";
    return this.http.request('delete', `${this.apiUrl}api/chamados/contato`, {
      body: contato, 
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()})})
      .pipe(
        catchError(this.handleError)
      );
  }

  ligacaoContatoChamado(chamado_id, contato): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chamados/ligacao/${chamado_id}`, contato, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadImagemS3(img): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/upload-arquivo`, img, {headers: new HttpHeaders({'authorization': this.tokenService.getToken()})})
      .pipe(
        catchError(this.handleError)
      );
  }

  imprimirPDFChamado(chamado_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chamados/impressao/pdf/${chamado_id}`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      responseType: 'blob'
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  obterHorarioComercial(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/expediente/empresa/obter-horario`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirProdutoOrcamento(produto_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/produtos/${produto_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  listagemChamadosNova(params, payload): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrlNova}manager/tickets`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropdownChamadosPorCliente(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/chamados/dropdown-paginado`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obtemConfigOSTicket(chamado_id: number): Observable<IMostraOSTicket> {
    this.httpOptions.params = "";
    return this.http.get<IMostraOSTicket>(`${this.apiUrl}api/chamados/mostra-ordem/${chamado_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
