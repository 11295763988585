import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TarefaService } from 'src/app/services/tarefa.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-finalizar-tarefa',
  templateUrl: './modal-finalizar-tarefa.component.html',
  styleUrls: ['./modal-finalizar-tarefa.component.scss']
})
export class ModalFinalizarTarefaComponent implements OnInit, OnDestroy {
  @Input() tarefa;
  codigoChamado;
  opcao = 'apontar';
  finalizar = {
    is_externo: false,
    is_comercial: false,
    data_criacao: null,
    total_horas: ''
  };
  selector: string;
  newSubs: Subscription;

  constructor(
    public modal: NgbActiveModal,
    private tarefaService: TarefaService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private elemRef: ElementRef,
  ) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    var chamado = this.tarefa.chamado_id.text.split('-');
    this.codigoChamado = chamado[0];    

    if(this.opcao === 'apontar') {
      this.finalizar.total_horas = this.tarefa.tempo_tarefa;
    }
    this.finalizar.data_criacao = this.tarefa.data_agendamento;
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  finalizarTarefa(): void {
    if(this.opcao !== 'nao_apontar') {
      if(this.opcao === 'apontar') {
        this.finalizar.total_horas = this.tarefa.tempo_tarefa;
        var horas = this.finalizar.total_horas.split(":");
        let payload = {
          objHorasTrabalhadas: {
            "chamado_id": this.tarefa.chamado_id.id,
            "cliente_id": this.tarefa.cliente_id.id,
            "descricao": this.tarefa.descricao || this.tarefa.descricao_html || '',
            "hora": horas[0],
            "is_externo": this.finalizar.is_externo,
            "is_comercial": this.finalizar.is_comercial,
            "minuto": horas[1],
            "tarefa_id": this.tarefa.tarefa_id
          }
        }
    
        this.newSubs = this.tarefaService.adicionarHorasTrabalhadas(payload).subscribe(
          result => {
            this.newSubs = this.tarefaService.finalizarTarefa({tarefa: {id: this.tarefa.tarefa_id}})
              .subscribe(results => {
                this.toastr.show(this.translate.instant('FINALIZADO_SUCESSO'), '', environment.toastrConfig('success'));
                this.modal.close('finalizou');
              }, error => {
                this.toastr.show(error, '', environment.toastrConfig('danger'));
              })
          }, error => {
            this.toastr.show(error, '', environment.toastrConfig('danger'));
          }
        )
      } else {
        var horas = this.finalizar.total_horas.split(":");
        let payload = {
          objHorasTrabalhadas: {
            "chamado_id": this.tarefa.chamado_id.id,
            "cliente_id": this.tarefa.cliente_id.id,
            "descricao": this.tarefa.descricao || this.tarefa.descricao_html || '',
            "hora": horas[0],
            "is_externo": this.finalizar.is_externo,
            "is_comercial": this.finalizar.is_comercial,
            "minuto": horas[1],
            "tarefa_id": this.tarefa.tarefa_id
          }
        }
    
        this.newSubs = this.tarefaService.adicionarHorasTrabalhadas(payload).subscribe(
          result => {
            this.newSubs = this.tarefaService.finalizarTarefa({tarefa: {id: this.tarefa.tarefa_id}})
              .subscribe(results => {
                this.toastr.show(this.translate.instant('FINALIZADO_SUCESSO'), '', environment.toastrConfig('success'));
                this.modal.close('finalizou');
              }, error => {
                this.toastr.show(error, '', environment.toastrConfig('danger'));
              })
          }, error => {
            this.toastr.show(error, '', environment.toastrConfig('danger'));
          }
        )
      }
    } else {
      this.newSubs = this.tarefaService.finalizarTarefa({tarefa: {id: this.tarefa.tarefa_id}})
        .subscribe(results => {
          this.toastr.show(this.translate.instant('FINALIZADO_SUCESSO'), '', environment.toastrConfig('success'));
          this.modal.close('finalizou');
        }, error => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
        })
    }
  }

  verificaSeDesativa(): boolean {
    if(this.opcao === 'apontar_custom') {
      if((this.finalizar.total_horas === null || this.finalizar.total_horas === '') || (this.finalizar.data_criacao === null || this.finalizar.data_criacao === '')) {
        return true;
      } else {
        return false;
      }
    } else {
      if(this.finalizar.data_criacao === null || this.finalizar.data_criacao === '') {
        return true;
      } else {
        return false;
      }
    }
  }

  zerarHoras(): void {
    this.finalizar.total_horas = '';
  }
}
