import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { WhatsappService } from 'src/app/services/whatsapp.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CountriesMask } from "src/app/variables/countries-phones";

@Component({
  selector: 'app-modal-msg-start-chat-whatsapp',
  templateUrl: './modal-msg-start-chat-whatsapp.component.html',
  styleUrls: ['./modal-msg-start-chat-whatsapp.component.scss']
})
export class ModalMsgStartChatWhatsappComponent implements OnInit, OnDestroy {
  @Input() data;
  content: any;
  template_content: any;
  newSubs: Subscription;

  constructor(
    private modal: NgbActiveModal,
    private whatsappService: WhatsappService,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.constructData();
    if(this.data.provider_id === 4) {
      this.verifyMetaTemplates(this.data.integration_id);
    }
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  close(result?): void {
    this.modal.close(result);
  }

  verifyMetaTemplates(integration_id): void {
    this.newSubs = this.whatsappService.wppOfficialGetTemplates(integration_id)
      .subscribe({
        next: (results) => {
          if(!results.length) {
            Swal.fire({
              text: this.translate.instant('ALERTA_SEM_TEMPLATE_META'),
              icon: 'warning',
              confirmButtonText: 'Ok'
            });
            this.modal.close();
          }
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          Swal.fire({
            text: this.translate.instant('ALERTA_SEM_TEMPLATE_META'),
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
          this.modal.close();
        }
      });
  }

  constructData(): void {
    if(this.data.provider_id === 4) {
      this.content = {
        from: null,
        numero_destino: null,
        chamado_id: null,
        template_name: null,
        language: null,
        cliente_id: null
      };
    } else {
      this.content = {
        numero_contato: null,
        chamado_id: null,
        mensagem: this.data.message || null,
        nome_contato: null,
        contato_id: null,
        cliente_id: null
      };
    }
  }

  changeTemplateProperties(properties): void {
    if(properties) {
      this.content = {
        template_name: properties.text,
        language: properties.lang,
      };
      this.template_content = properties.content;
    } else {
      this.content = {
        template_name: null,
        language: null,
      };
      this.template_content = null;
    }
  }

  verifyDisabled(): boolean {
    if(this.data.provider_id === 4) {
      return !this.content.template_name && !this.content.language;
    } else {
      return !this.content.mensagem;
    }
  }

  getMaskByCountry(number: string | number): string {
    return CountriesMask(number.toString().length, number.toString());
  }
}
