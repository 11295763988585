import { HttpHeaders, HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { TokenService } from "../core/token/token.service";
import { catchError } from 'rxjs/operators';
import { WebsocketService } from "../core/websocket/websocket.service";

@Injectable({
    providedIn: 'root'
})
export class RelatorioPersonalizadoService {
    private apiUrl = environment.apiGestorUrlLocal;
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() }),
        params: {}
    };
    socket;
    public messages: any

    constructor(private http: HttpClient, private tokenService: TokenService, private websocketService: WebsocketService, private modalService: NgbModal) { }

    create(payload): Observable<any> {
        this.httpOptions.params = ''
        return this.http.post(`${this.apiUrl}api/relPersonalizado`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    delete(id): Observable<any> {
        this.httpOptions.params = "";
        return this.http.delete(`${this.apiUrl}api/relPersonalizado/${id}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    dropdownColunas(id): Observable<any> {
        this.httpOptions.params = ''
        return this.http.get(`${this.apiUrl}api/relPersonalizado/dropdown-novo/colunas/${id}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    dropdownEntidades(): Observable<any> {
        this.httpOptions.params = ''
        return this.http.get(`${this.apiUrl}api/relPersonalizado/dropdown/entidades`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    dropdownFiltros(id): Observable<any> {
        this.httpOptions.params = ''
        return this.http.get(`${this.apiUrl}api/relPersonalizado/dropdown-novo/filtros/${id}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    exportarCSV(id): Observable<any> {
        this.httpOptions.params = ''
        return this.http.get(`${this.apiUrl}api/relPersonalizado/exportar/csv/${id}`, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
            responseType: 'blob'
          })
            .pipe(
                catchError(this.handleError)
            )
    }

    exportarExcel(id): Observable<any> {
        this.httpOptions.params = ''
        return this.http.get(`${this.apiUrl}api/relPersonalizado/exportar/excel/${id}`, {
            headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel', 'authorization': this.tokenService.getToken()}),
            responseType: 'blob'
          })
            .pipe(
                catchError(this.handleError)
            )
    }

    exportarPDF(id): Observable<any> {
        this.httpOptions.params = ''
        return this.http.get(`${this.apiUrl}api/relPersonalizado/exportar/pdf/${id}`, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
            responseType: 'blob'
          })
            .pipe(
                catchError(this.handleError)
            )
    }

    geraRelatorio(payload): Observable<any> {
        this.httpOptions.params = ''
        return this.http.post(`${this.apiUrl}api/relPersonalizado/geraRelatorio`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    getAllFiltro(payload): Observable<any> {
        this.httpOptions.params = ''
        return this.http.post(`${this.apiUrl}api/relPersonalizado/getAllFiltro`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    getById(id): Observable<any> {
        this.httpOptions.params = ''
        return this.http.get(`${this.apiUrl}api/relPersonalizado/${id}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    getMeusRelatorios(): Observable<any> {
        this.httpOptions.params = ''
        return this.http.get(`${this.apiUrl}api/relPersonalizado/list/meusrelatorios`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    getRelatoriosCompartilhados(): Observable<any> {
        this.httpOptions.params = ''
        return this.http.get(`${this.apiUrl}api/relPersonalizado/list/relatorioscompartilhados`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    getTabelasByMaster(id): Observable<any> {
        this.httpOptions.params = ''
        return this.http.get(`${this.apiUrl}api/relPersonalizado/getTabelasByMaster/${id}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    update(payload): Observable<any> {
        this.httpOptions.params = ''
        return this.http.put(`${this.apiUrl}api/relPersonalizado`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    createPivotJson(payload): Observable<any> {
        this.httpOptions.params = ''
        return this.http.post(`${this.apiUrl}api/relPersonalizado/PivotJson`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    updatePivotJson(payload): Observable<any> {
        this.httpOptions.params = ''
        return this.http.put(`${this.apiUrl}api/relPersonalizado/PivotJson`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    listagemRelatorios(params, filtro): Observable<any> {
        this.httpOptions.params = params
        return this.http.post(`${this.apiUrl}api/relPersonalizado/listagem`, filtro, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    buscarDadosRelatorio(id): Observable<any> {
        this.httpOptions.params = ''
        return this.http.get(`${this.apiUrl}api/relPersonalizado/buscar-dados-relatorio/${id}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Ops! Ocorreu um erro:', error.error.message);
        } else {
            console.error(
                `Backend retornou o código ${error.status}, ` +
                `o body é: ${error.error}`);
        }
        return throwError(
            error.error);
    }

}