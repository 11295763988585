import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TokenService } from "../core/token/token.service";

@Injectable({
  providedIn: "root",
})
export class IntegrationService {
  private apiUrl = environment.apiGestorUrlLocal;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      authorization: this.tokenService.getToken(),
    }),
    params: {},
  };

  constructor(private tokenService: TokenService, private http: HttpClient) { }

  buscarIntegracoes(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/configuracoes/integracao/buscar-integracoes/paginado`,
        filtros,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getListaEspectra(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(`${this.apiUrl}api/usuarios/listGrid`, filtros, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  atualizarRamal(id, payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/usuarios/atualizar-ramal/${id}`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  dropdownServico(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/clientes/contratos/dropdown/servicos`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  dropdownTipoServico(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/clientes/contratos/configuracao/tipo-servicos`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  listaIntegracaoBC(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/clientes/contratos/configuracao/servico-integracao/lista`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  removeTipoServico(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .delete(
        `${this.apiUrl}api/clientes/contratos/remover-tipo-servico/${id}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  salvarServicoIntegracaoBC(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/clientes/contratos/configuracao/servico-integracao`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getIntegracaoBomControle(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/configuracoes/integracao/bomcontrole`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  salvarToken(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/configuracoes/integracao/bomcontrole`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  desativarIntegracaoBC(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/configuracoes/integracao/bomcontrole/desativar/${id}`,
        "",
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  desativarIntegracaoEspectra(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/configuracoes/integracao/espectra/desativar/${id}`,
        "",
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  desativarIntegracaoVcom(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/configuracoes/integracao/vcom/desativar/${id}`,
        "",
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  adicionarIntegracaoBC(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/configuracoes/integracao/bomcontrole`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  adicionarIntegracaoEspectra(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/configuracoes/integracao/espectra`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  adicionarIntegracaoVcom(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/configuracoes/integracao/vcom`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  obterIntegracaoEspectra(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/configuracoes/integracao/espectra`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  ativarIntegracaoEspectra(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/configuracoes/integracao/espectra/ativar/${id}`,
        "",
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  adicionarIntegracaoOmni(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/configuracoes/integracao/omni`,
        "",
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  addChatGPTIntegration(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/configuracoes/integracao/openai`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  updateChatGPTIntegrationStatus(payload: any, id: number): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/configuracoes/integracao/openai/${id}`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getChatGPTIntegrationStatus(): Observable<any> {
    return this.http
      .get(
        `${this.apiUrl}api/configuracoes/integracao/openai`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getChatGPTPowershellSuggestion(ask: object): Observable<any> {
    return this.http
      .post(
        `${this.apiUrl}api/power-shell/chat-gpt/comando`,
        ask,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  desativarIntegracaoOmni(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/configuracoes/integracao/omni/desativar/${id}`,
        "",
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  obterIntegracaoOmni(): Observable<any> {
    return this.http
      .get(`${this.apiUrl}api/configuracoes/integracao/omni`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  ativarIntegracaoOmni(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/configuracoes/integracao/omni/ativar/${id}`,
        "",
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  //------- Integrações usuário-cliente -------//
  listarIntegracoesUsuarioCliente(filtros, params): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/integracao-usuario/listar`,
        filtros,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  atualizarIntegracaoUsuarioCliente(id, payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/integracao-usuario/${id}`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  criarIntegracaoUsuarioCliente(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/integracao-usuario`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  excluirIntegracaoUsuarioCliente(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .delete(`${this.apiUrl}api/integracao-usuario/${id}`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  obterLinkAutenticacaoIntegracaoUsuarioCliente(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/integracao-usuario/obter-link-autenticacao`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  sincronizarIntegracaoUsuarioCliente(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/integracao-usuario/sincronizar/`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  addServicoCobrancaBC(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/clientes/contratos/cobranca/servico`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  adicionarIntegracaoNative(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/configuracoes/integracao/native`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  desativarIntegracaoNative(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/configuracoes/integracao/native/desativar/${id}`,
        "",
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  ativarIntegracaoNative(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/configuracoes/integracao/native/ativar/${id}`,
        "",
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  obterIntegracaoNative(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/configuracoes/integracao/native`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  atualizarIntegracao(payload, id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/configuracoes/integracao/omni/atualizar/${id}`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getGoogleIntegrationSchedule(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/integracao-agenda/auth`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  deleteGoogleIntegrationSchedule(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .delete(`${this.apiUrl}api/integracao-agenda/revoke`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  validAuthTecnicoGoogle(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .get(`${this.apiUrl}api/integracao-agenda/validaAuthTecnico`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getOutlookIntegrationSchedule(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/integracao-agenda/authOutlook`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  connectAcronis(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/configuracoes/integracao/authAcronis`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  disconnectAcronis(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/configuracoes/integracao/revokeAuthAcronis`,
        null,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  listaAlertasAcronis(configPagination, filtros?): Observable<any> {
    if (filtros) {
      let allParams = { ...configPagination, ...filtros };
      this.httpOptions.params = allParams;
    } else {
      this.httpOptions.params = configPagination;
    }
    return this.http.get(`${this.apiUrl}api/acronis/tipoAlertaAcronis`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropDownSeveridadesAcronis(): Observable<any> {
    this.httpOptions.params = null;
    return this.http.get(`${this.apiUrl}api/acronis/severidade/dropdown`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropDownCategoriasAcronis(): Observable<any> {
    this.httpOptions.params = null;
    return this.http.get(`${this.apiUrl}api/acronis/categoria/dropdown`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropDownAcoesAcronis(): Observable<any> {
    this.httpOptions.params = null;
    return this.http.get(`${this.apiUrl}api/acronis/acoes/dropdown`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  criarAlertaAcronis(payload): Observable<any> {
    this.httpOptions.params = null;
    return this.http
      .post(`${this.apiUrl}api/acronis/tipoAlertaAcronis`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  atualizaAlertaAcronis(id, payload): Observable<any> {
    this.httpOptions.params = null;
    return this.http
      .put(`${this.apiUrl}api/acronis/tipoAlertaAcronis/${id}`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deletarAlertaAcronis(id): Observable<any> {
    this.httpOptions.params = null;
    return this.http
      .delete(`${this.apiUrl}api/acronis/tipoAlertaAcronis/${id}`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  listaClientesMilvusAcronis(configPagination, filtros?): Observable<any> {
    if (filtros) {
      let allParams = { ...configPagination, ...filtros };
      this.httpOptions.params = allParams;
    } else {
      this.httpOptions.params = configPagination;
    }
    return this.http.get(`${this.apiUrl}api/acronis/listClientsAcronis`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  contadorClientesSincronizadosAcronis(): Observable<any> {
    this.httpOptions.params = null;
    return this.http.get(`${this.apiUrl}api/acronis/countClientesAcronis`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  ativarDesativarSincronizacaoAutomaticaAcronis(id, payload): Observable<any> {
    this.httpOptions.params = null;
    return this.http.put(`${this.apiUrl}api/acronis/sincronizarDesincronizarclientsAcronis/${id}`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  vincularClienteAcronis(id, payload): Observable<any> {
    this.httpOptions.params = null;
    return this.http.put(`${this.apiUrl}api/acronis/sincronizarManualClientsAcronis/${id}`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  desvincularClienteAcronis(id, payload): Observable<any> {
    this.httpOptions.params = null;
    return this.http.put(`${this.apiUrl}api/acronis/desincronizarClientsAcronis/${id}`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  integrarClientesMilvusAcronis(): Observable<any> {
    this.httpOptions.params = null;
    return this.http.get(`${this.apiUrl}api/acronis/clientsAcronis`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteOutlookIntegrationSchedule(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .delete(`${this.apiUrl}api/integracao-agenda/revokeOutlook`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  validAuthTecnicoOutlook(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .get(`${this.apiUrl}api/integracao-agenda/validaAuthOutlookTecnico`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  mudarApontamentoLigacaoTicket(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http
      .put(`${this.apiUrl}api/configuracoes/integracao/mudar-apontamento-ligacao-ticket`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getModelosChatGpt(): Observable<any> {
    return this.http
      .get(
        `${this.apiUrl}api/configuracoes/integracao/openai/obterModelosChatGpt`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("Ops! Ocorreu um erro:", error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`
      );
    }
    return throwError(error.error);
  }
}
