import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';
@Injectable({
    providedIn: 'root'
})
export class FormularioTicketService {
    private apiUrl = environment.apiGestorUrlLocal;
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() }),
        params: {}
    };

    constructor(private http: HttpClient, private tokenService: TokenService) { }

    AtualizaFormularioChamado(payload): Observable<any> {
        this.httpOptions.params = "";
        return this.http.put(`${this.apiUrl}api/chamados/formulario/${payload.id}`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    CriarFormularioChamado(payload): Observable<any> {
        this.httpOptions.params = "";
        return this.http.post(`${this.apiUrl}api/chamados/formulario`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    FormularioDropdownWhatsapp(): Observable<any> {
        this.httpOptions.params = '';
        return this.http.get(`${this.apiUrl}api/chamados/formulario/dropdown?is_whatsapp=true`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    DropdownCamposFormularioChamado(): Observable<any> {
        this.httpOptions.params = '';
        return this.http.get(`${this.apiUrl}api/chamados/formulario/campos/dropdown`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    ExcluirFormularioChamado(id): Observable<any> {
        this.httpOptions.params = '';
        return this.http.delete(`${this.apiUrl}api/chamados/formulario/${id}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    ListaFormularioChamado(params, filtros): Observable<any> {
        this.httpOptions.params = params;
        return this.http.post(`${this.apiUrl}api/chamados/formulario/listagem`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    ObterFormularioChamado(id): Observable<any> {
        this.httpOptions.params = '';
        return this.http.get(`${this.apiUrl}api/chamados/formulario/${id}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    obterIframe(id): Observable<any> {
        this.httpOptions.params = '';
        return this.http.get(`${this.apiUrl}api/chamados/formulario/iframe/${id}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Ops! Ocorreu um erro:', error.error.message);
        } else {
            console.error(
                `Backend retornou o código ${error.status}, ` +
                `o body é: ${error.error}`);
        }
        return throwError(
            error.error);
    }
}
