import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { TokenService } from "../core/token/token.service";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, catchError, throwError } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ExpedientesService {
    private apiUrl = environment.apiGestorUrlNova;
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() }),
        params: {}
    };
    
    constructor(
        private http: HttpClient,
        private tokenService: TokenService
    ) { }

    listaExpedientes(paginacao, filtros): Observable<any> {
        this.httpOptions.params = paginacao;
        return this.http.post(`${this.apiUrl}manager/times/list`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    cadastraExpediente(expediente): Observable<any> {
        this.httpOptions.params = null;
        return this.http.post(`${this.apiUrl}manager/times`, expediente, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    atualizaExpediente(expediente, expediente_id): Observable<any> {
        this.httpOptions.params = null;
        return this.http.put(`${this.apiUrl}manager/times/${expediente_id}`, expediente, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    excluiExpediente(expediente_id): Observable<any> {
        this.httpOptions.params = null;
        return this.http.delete(`${this.apiUrl}manager/times/${expediente_id}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    dropDownExpedientes(): Observable<any> {
        this.httpOptions.params = null;
        return this.http.get(`${this.apiUrl}manager/times/dropdown`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    getExpedientePorId(expediente_id): Observable<any> {
        this.httpOptions.params = null;
        return this.http.get(`${this.apiUrl}manager/times/${expediente_id}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Ops! Ocorreu um erro:', error.error.message);
        } else {
            console.error(
                `Backend retornou o código ${error.status}, ` +
                `o body é: ${error.error}`);
        }
        return throwError(
            error.error);
    }
}