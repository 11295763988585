import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private apiUrl = environment.apiGestorUrlLocal;

    mostrarDoisfatores = new BehaviorSubject<any>(false);

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() }),
        params: {}
    };

    constructor(private http: HttpClient, private tokenService: TokenService) { }

    getConfigAuth(): Observable<any> {
        const _headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() });
        if (!_headers.get('authorization') || !_headers.get('authorization') === null) {
            _headers.set('authorization', this.tokenService.getToken())
        }
        return this.http.get(`${this.apiUrl}api/2fa`, { headers: _headers })
        .pipe(
          catchError(this.handleError)
        );
    }

    updateConfigAuth(params: any): Observable<any> {
        this.httpOptions.params = '';
        return this.http.post(`${this.apiUrl}api/2fa`, params, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }

    get2FAAuth(params: any) : Observable<any> {        
        const _headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() });
        if (!_headers.get('authorization') || !_headers.get('authorization') === null) {
            _headers.set('authorization', this.tokenService.getToken())
        }
        return this.http.post(`${this.apiUrl}api/2fa/auth`, params, { headers: _headers })
        .pipe(
          catchError(this.handleError)
        );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Ops! Ocorreu um erro:', error.error.message);
        } else {
            console.error(
                `Backend retornou o código ${error.status}, ` +
                `o body é: ${error.error}`);
        }
        return throwError(
            error.error);
    }
}