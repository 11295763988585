import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleCrypt } from 'ngx-simple-crypt';
import { Usuario } from 'src/app/models/usuario.model';  
import * as CryptoJS from 'crypto-js'; 
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LocalstorageService } from '../localstorage/localstorage.service';
declare let moment: any;

@Injectable({ providedIn: 'root' })
export class TokenService {
    simpleCrypt = new SimpleCrypt();
    usuario = Usuario;

    constructor(
        private router: Router, 
        private toastr: ToastrService, 
        public translate: TranslateService,
        private storageService: LocalstorageService
    ) {
        
    }
    
    checkViewDeskTop() : boolean {
        return this.getUsuario().atribuir_ticket_outras_mesas;
    }

    hasToken() {
        return !!this.getToken();
    }

    setToken(usuario) {
        let encodedUser = CryptoJS.AES.encrypt(JSON.stringify(usuario), "szHYxd7tz");
        window.localStorage.setItem("JKt6EHYnC", encodedUser);
        //let testeDecr = CryptoJS.AES.decrypt(teste, "szHYxd7tz");
        //console.log(JSON.parse(testeDecr.toString(CryptoJS.enc.Utf8)));
        //let encodedUser: string = SimpleCrypt.encodeDefault("szHYxd7tz", usuario);
        //window.localStorage.setItem("JKt6EHYnC", encodedUser);        
    }

    setFoto(url) : void {
        let usuario = this.getUsuario();
        usuario.foto = url;
        this.setToken(usuario);
    }

    setTipoListagem(tipo_listagem) : void {
        let usuario = this.getUsuario();
        usuario.tipo_listagem = tipo_listagem;
        this.setToken(usuario);
    }

    setTipoAtendimento(tipo_atendimento_id) : void {
        let usuario = this.getUsuario();
        usuario.tipo_atendimento_id = tipo_atendimento_id;
        this.setToken(usuario);
    }

    getToken() {
        if (window.localStorage.getItem("JKt6EHYnC") !== null) {
            //let decodedUser: object = SimpleCrypt.decodeDefault("szHYxd7tz", window.localStorage.getItem("JKt6EHYnC"));
            try {
                let encryptedData = CryptoJS.AES.decrypt(window.localStorage.getItem("JKt6EHYnC"), "szHYxd7tz");
                let decodedUser = JSON.parse(encryptedData.toString(CryptoJS.enc.Utf8));      
                return decodedUser['AccessToken'];
            } catch(e){          
                this.toastr.show(this.translate.instant('ERRO_AUTENTICACAO_SISTEMA'), '', environment.toastrConfig('warning'));    
                console.log(e);                
                this.storageService.logoutWithLocalData(true);
                return null;
            }
        }
        return null;
    }

    removeToken() {
        this.storageService.logoutWithLocalData(true);
        window.addEventListener('storage', (event) => {
            if (event.storageArea == localStorage) {
                window.localStorage.removeItem("JKt6EHYnC");
            }
        }, false);
    }

    getUsuario() {
        if (window.localStorage.getItem("JKt6EHYnC") !== null) {
            //let decodedUser: object = SimpleCrypt.decodeDefault("szHYxd7tz", window.localStorage.getItem("JKt6EHYnC"));
            //const retornedUsuario = new Usuario().deserialize(decodedUser);
            try {
                let encryptedData = CryptoJS.AES.decrypt(window.localStorage.getItem("JKt6EHYnC"), "szHYxd7tz");
                let decodedUser = JSON.parse(encryptedData.toString(CryptoJS.enc.Utf8));
                const retornedUsuario = new Usuario().deserialize(decodedUser);

                return retornedUsuario;
            } catch(e) {
                this.toastr.show(this.translate.instant('ERRO_AUTENTICACAO_SISTEMA'), '', environment.toastrConfig('warning'));    
                this.storageService.logoutWithLocalData(true);
                console.log(e);
                return null;
            }
        }

        return null;
    }

    getAllInfos() {
        if (window.localStorage.getItem("JKt6EHYnC") !== null) {
            //let decodedUser: object = SimpleCrypt.decodeDefault("szHYxd7tz", window.localStorage.getItem("JKt6EHYnC"));
            
            try {
                let encryptedData = CryptoJS.AES.decrypt(window.localStorage.getItem("JKt6EHYnC"), "szHYxd7tz");
                let decodedUser = JSON.parse(encryptedData.toString(CryptoJS.enc.Utf8));
                return decodedUser;
            } catch(e) {
                this.toastr.show(this.translate.instant('ERRO_AUTENTICACAO_SISTEMA'), '', environment.toastrConfig('warning'));    
                this.storageService.logoutWithLocalData(true);
                console.log(e);
                return null;
            }
        }

        return null;
    }

    checkPermissions(chave) {
        if (window.localStorage.getItem("JKt6EHYnC") !== null) {
            //let decodedUser: object = SimpleCrypt.decodeDefault("szHYxd7tz", window.localStorage.getItem("JKt6EHYnC"));
            try {
                let encryptedData = CryptoJS.AES.decrypt(window.localStorage.getItem("JKt6EHYnC"), "szHYxd7tz");
                let decodedUser = JSON.parse(encryptedData.toString(CryptoJS.enc.Utf8));
                if (decodedUser['is_admin']) {
                    return true;
                } else {
                    if(decodedUser['permissoes']) {
                        let _permissions = (decodedUser['permissoes'].permissoes) ? decodedUser['permissoes'].permissoes : decodedUser['permissoes'];
                        if (_permissions.indexOf(chave) !== -1) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
            } catch(e) {
                this.toastr.show(this.translate.instant('ERRO_AUTENTICACAO_SISTEMA'), '', environment.toastrConfig('warning'));    
                this.storageService.logoutWithLocalData(true);     
                console.log(e);          
                return null;
            }
        }

        return null;
    }   

    convertDateTimezone(date): string {
        let zone = this.getUsuario().timezone;
        let m = moment.tz(date, 'YYYY-MM-DD HH:mm:ss', zone);
        let dataFormatada = m.utc().format('YYYY-MM-DD HH:mm:ss');
        return dataFormatada;
    }

    convertDateTimezoneStockFormat(date): string {
        let zone = this.getUsuario().timezone;
        let m = moment.tz(date, 'YYYY-MM-DD HH:mm:ss', zone);
        let dataFormatada = m.utc().format();
        return dataFormatada;
    }

    convertDateTimezoneToView(date, noSeconds?): string {
        let _date = date;
        _date.setHours(_date.getHours() - 3);
        let zone = this.getUsuario().timezone;
        let m = moment.tz(_date, 'YYYY-MM-DD HH:mm:ss', zone);
        let dataFormatada = m.utc().format((noSeconds) ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY HH:mm:ss');
        return dataFormatada;
    }

    convertDateTimezoneToViewWithTZ(date): string {
        let _date = date;
        let zone = this.getUsuario().timezone;
        _date.setHours(_date.getHours() - 3);
        let m = moment.tz(date, 'YYYY-MM-DD HH:mm:ss',zone);
        let dataFormatada = m.utc().format('DD/MM/YYYY HH:mm:ss');
        return dataFormatada;
    }

    convertDateTimezoneToViewWithoutTime(date): string {
        let zone = this.getUsuario().timezone;
        let localTime = moment.utc(date).toDate();
        let dataFormatada = moment.tz(localTime, zone).format('DD/MM/YYYY');
        return dataFormatada;
    }

    convertDateWithoutTime(date): string {
        let zone = this.getUsuario().timezone;
        let localTime = moment.utc(date).toDate();
        let dataFormatada = moment.tz(localTime, zone).format('YYYY-MM-DD');
        return dataFormatada;
    }

    convertSubractDateTimezone(date): string {
        let zone = this.getUsuario().timezone;
        let m = moment.tz(date, 'YYYY-MM-DD HH:mm:ss', zone);
        let dataFormatada = m.utc().format('YYYY-MM-DD HH:mm:ss');
        return dataFormatada;
    }

    setTrialExpirado(): void {
        let trialExpirado = CryptoJS.AES.encrypt(JSON.stringify({trial_expirado: true}), "mdaEksdc");
        window.localStorage.setItem("Kd0d82bdsa", trialExpirado);
    }

    getTrialExpirado(): boolean {
        if(window.localStorage.getItem("Kd0d82bdsa") !== null) {
            try {
                let encryptedData = CryptoJS.AES.decrypt(window.localStorage.getItem("Kd0d82bdsa"), "mdaEksdc");
                let decodedTrial = JSON.parse(encryptedData.toString(CryptoJS.enc.Utf8));
                return decodedTrial.trial_expirado;
            } catch(e) {                
                this.toastr.show(this.translate.instant('ERRO_AUTENTICACAO_SISTEMA'), '', environment.toastrConfig('warning'));                
                this.storageService.logoutWithLocalData(true);
                console.log(e);
                return null;
            }
        }
    }

    generateCryptoCode(code): string {
        let _cryptoCode = CryptoJS.AES.encrypt(JSON.stringify(code), "szHYxd7tz");
        return _cryptoCode;
    }

    decodeCryptoCodeOperator(key): string {
        let encryptedData = CryptoJS.AES.decrypt(localStorage.getItem(key), "szHYxd7tz");
        return JSON.parse(encryptedData.toString(CryptoJS.enc.Utf8));
    }

    setCodeOperator(code): void {
        this.usuario.LJHHHASDF = code;
    }

    setCodePasswordVault(code): void {
        this.usuario.LHHHASDFJ = code;
    }

    getTipoPerfilUsuario(): number|null {
        if (this.getUsuario()) {
            return this.getUsuario().tipo_usuario_id;
        }
        
        return null
    }
}