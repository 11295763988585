import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriasService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
    params: {}
  };

  constructor(private tokenService: TokenService, private http: HttpClient) { }

  //-------- CATEGORIA PRIMÁRIA --------//
  getListaCategoriaPrimaria(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/categoriaprimaria/list`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropDownCategoriaPrimaria(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/categoriaprimaria/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCategoriaPrimariaById(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/categoriaprimaria/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  createCategoriaPrimaria(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/categoriaprimaria`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateCategoriaPrimaria(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/categoriaprimaria`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  //-------- CATEGORIA SECUNDÁRIA --------//
  getDropDownCategoriaSecundaria(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/categoriasecundaria/dropdownlistChamado`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getListaCategoriaSecundariaByPrimariaId(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/categoriasecundaria/list`, filtros, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }
  
  getCategoriaSecundariaById(primaria_id): Observable<any> {
  this.httpOptions.params = "";
  return this.http.get(`${this.apiUrl}api/categoriasecundaria/${primaria_id}`, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  createCategoriaSecundaria(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/categoriasecundaria`, payload, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  updateCategoriaSecundaria(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/categoriasecundaria`, payload, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  getPrioridadeCategoriaSecundaria(categoria_secundaria_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/categoriasecundaria/obter-prioridade/${categoria_secundaria_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }
  
  //-------- CATEGORIAS CLIENTE --------//
  salvarVisualizacaoCategoria(categorias): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/clientes/salvar-visualizacao-categorias`, categorias, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirVisualizacaoCategoria(categoria): Observable<any> {
    this.httpOptions.params = "";
    return this.http.request('delete', `${this.apiUrl}api/clientes/excluir-visualizacao-categorias`, {
      body: categoria, 
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()})})
      .pipe(
        catchError(this.handleError)
      );
  }

  getListaPersonalizacaoCategoria(id, params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/cliente-personalizacao-categoria/lista-categoria-personalizada/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  postListaPersonalizacaoCategoria(filtro, params, cliente_id): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/cliente-personalizacao-categoria/lista-categoria-personalizada/${cliente_id}`, filtro, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionaCategoriaSecundaria(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/categoriasecundaria/categoria-personalizada-cliente`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteCategoriaSecundaria(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.request('delete',`${this.apiUrl}api/categoriasecundaria/categoria-personalizada-cliente`,
    {
      body: payload, 
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()})})
      .pipe(
        catchError(this.handleError)
      );
  }

  getPersonalizacaoCategoria(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/cliente-personalizacao-categoria/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updatePersonalizacaoCategoria(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/cliente-personalizacao-categoria/`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropDownCategoriaSecundariaPersonalizada(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/categoriasecundaria/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
