import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class TarefaService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() }),
    params: {}
  };

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getListagemTipoTarefas(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/tarefa-tipo/listagem`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  createTipoTarefa(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/tarefa-tipo`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateTipoTarefa(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/tarefa-tipo`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteTipoTarefa(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/tarefa-tipo/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownChamadosTarefa(cliente_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/tarefa/chamados/dropdown/${cliente_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownTipoTarefa(params?): Observable<any> {
    this.httpOptions.params = (params) ? params : '';
    return this.http.get(`${this.apiUrl}api/tarefa-tipo/dropdown`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionarNovaTarefa(tarefa): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/tarefa`, tarefa, { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  alterarTarefa(tarefa): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/tarefa`, tarefa, { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAnexosTarefa(tarefa_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/tarefa/anexostarefa/${tarefa_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadAnexoTarefa(tarefa): Observable<any> {
    this.httpOptions.params = "";
    var formData = new FormData();

    if (tarefa.file.length !== undefined) {
      for (var x = 0; x < tarefa.file.length; x++) {
        formData.append("file[]", tarefa.file[x]);
      }
    } else {
      formData.append("file[]", tarefa.file);
    }    

    formData.append("tarefa_id", tarefa.tarefa.id);
    if (tarefa.tarefa && tarefa.tarefa.cliente_id) {
      formData.append("cliente_id", tarefa.tarefa && tarefa.tarefa.cliente_id ? tarefa.tarefa.cliente_id : null);      
    }

    return this.http.post(`${this.apiUrl}api/tarefa/anexo`, formData, { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteAnexoTarefa(anexo_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/tarefa/anexo/${anexo_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadImagemTarefa(file): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/base-conhecimento/adicionaArquivo`, file,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  listaTarefasChamado(chamado, params?): Observable<any> {
    this.httpOptions.params = (params) ? params : "";
    return this.http.post(`${this.apiUrl}api/tarefa/listTarefas`, chamado, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTarefaById(tarefa_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/tarefa/getTarefa/${tarefa_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  finalizarTarefa(tarefa): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/tarefa/finalizar`, tarefa, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  reagendarTarefa(tarefa): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/tarefa/reagendar`, tarefa, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  buscarTarefasAgendadas(filtros, params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/tarefa/historicoTarefas`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirTarefa(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/tarefa/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionarHorasTrabalhadas(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/tarefa/horasTrabalhadas`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
