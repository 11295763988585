import { Component, OnInit, Input, ElementRef, OnDestroy } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { TokenService } from "src/app/core/token/token.service";
import { ChatService } from "src/app/services/chat.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalHoraFinalizarChatComponent } from "../modal-hora-finalizar-chat/modal-hora-finalizar-chat.component";
import { Subscription } from "rxjs";

@Component({
  selector: "app-chat-finalizar",
  templateUrl: "./chat-finalizar.component.html",
  styleUrls: ["./chat-finalizar.component.scss"],
})
export class ChatFinalizarComponent implements OnInit, OnDestroy {

  @Input() chatData;
  @Input() socketChat;
  tokenData: any;
  tabulacao: any;
  tabulacaoLista: any;
  comentario: any = '';
  contadores: any;
  selector: string;
  newSubs: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private tokenService: TokenService,
    private chatService: ChatService,
    public translate: TranslateService,
    private elemRef: ElementRef,
    private modalService: NgbModal,
  ) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85'
    this.tokenData = this.tokenService.getAllInfos();
    //console.log(this.chatData)
    this.loadTabulacao();
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  close(result?) {
    if (result) {
      this.activeModal.close(result);
    } else {
      this.activeModal.close();
    }
  }

  loadTabulacao() {
    this.newSubs = this.chatService.getTabulacao(this.chatData.widget.id, true)
      .subscribe((res) => {
        //console.log(res);
        this.tabulacaoLista = res.dropDown
      })
  }

  changeTabulacao(event) {
    //console.log(event)
    this.tabulacao = event;
  }

  fecharFinalizar(): void {
    this.close();
  }

  openModalFinalizarTarefaChat() {
    const modalRef = this.modalService.open(ModalHoraFinalizarChatComponent, {
      scrollable: false,
      windowClass: "modal-custom-milvus",
      size: "medio",
      keyboard: false,
      backdrop: "static",
    });

    modalRef.componentInstance.chatData = this.chatData;
    modalRef.componentInstance.infoFinalizacao = {
      comentario: this.comentario,
      tabulacao: this.tabulacao
    }

    modalRef.result.then((result) => {
      if (result === 'finalizado') {
        this.close(result)
      }
    });
  }

  salvar() {
    if (this.chatData && this.chatData.chamado_vinculado) {
      if (!this.tabulacao || !this.comentario) {
        this.toastr.show(this.translate.instant("PREENCHA_CAMPOS_OBRIGATORIOS"), "", environment.toastrConfig("danger"));
        return;
      }
      this.openModalFinalizarTarefaChat()
    } else {
      if (!this.tabulacao || !this.comentario) {
        this.toastr.show(this.translate.instant("PREENCHA_CAMPOS_OBRIGATORIOS"), "", environment.toastrConfig("danger"));
        return;
      }
      var finalizarChat = { chat_id: this.chatData._id, tabulacao: this.tabulacao.id, comentario: this.comentario };
      this.chatService.finalizarChat(finalizarChat)
        .subscribe((res) => {
          //console.log(res);
          this.close('finalizado');
        }
      )
    }
  }

  verificarComentario() {
    return this.comentario === '';
  }

}
