import { SimpleCrypt } from 'ngx-simple-crypt';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { TokenService } from '../token/token.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  simpleCrypt = new SimpleCrypt();
  private storageKey: string = "szHYxd7tz";

  constructor() { }

  storageItem(name:string, content:any): void {
    let itemExists = this.getStoredItem(name);
    if(itemExists !== null) {
      this.removeStoredItem(name);
    }
    
    const encryptContent = CryptoJS.AES.encrypt(JSON.stringify(content), this.storageKey);
    window.localStorage.setItem(name, encryptContent);
  }

  storageItemSimple(name:string, content:any): void {
    let itemExists = this.getStoredItemSimple(name);
    if(itemExists) {
      this.removeStoredItem(name);
    }

    const encodeContent = SimpleCrypt.encodeDefault(this.storageKey, content);
    window.localStorage.setItem(name, encodeContent);
  }

  getStoredItem(name:string): any {
    const encryptedItem = window.localStorage.getItem(name);
    if(encryptedItem !== null) {
      try {
        const decryptedContent = CryptoJS.AES.decrypt(encryptedItem, this.storageKey);
        const decodedContent = JSON.parse(decryptedContent.toString(CryptoJS.enc.Utf8));
        return decodedContent;
      } catch(e) {
        return null;
      }
    }
    return null;
  }

  getStoredItemSimple(name:string): any {
    if(window.localStorage.getItem(name)) {
      return SimpleCrypt.decodeDefault(this.storageKey, window.localStorage.getItem(name));
    } else {
      return null;
    }
  }

  removeStoredItem(name:string): void {    
    window.localStorage.removeItem(name);
  }

  getStoredItemsByName(name: string) {
    var filtrosStored: {} = this.getStoredItemSimple(name);
    if (filtrosStored !== null) {
      return JSON.parse(JSON.stringify(filtrosStored));
    }
  }

  setStoredItemsByName(name: string, item: any) {
    if (item) {
      this.storageItemSimple(name, item);
    }
  }
  
  logoutWithLocalData(noReload?: boolean): void {
    let _tema = localStorage.getItem('theme');
    let _usaFiltroNovo = localStorage.getItem('usa_filtro_novo');

    let _filtroDashboardTicket = this.getStoredItemsByName('listagemDashboardTicket');
    let _filtroDashboardCanaisAtendimento = this.getStoredItemsByName('listagemCanaisAtendimento');
    let _filtroDashboardChat = this.getStoredItemsByName('listagemDashboardChat');
    let _filtroDashboardUsersStatus = this.getStoredItemsByName('listagemDashboardUsersStatus');
    let _filtroDashboardClassificacaoInterna = this.getStoredItemsByName('listagemDashboardClassificacaoInterna');
    let _filtroDashboardClassificacaoTicketCliente = this.getStoredItemsByName('listagemDashboardClassificacaoTicketCliente');
    let _filtroDashboardDashboard = this.getStoredItemsByName('listagemDashboardDashboard');
    let _filtroDashboardInventario = this.getStoredItemsByName('listagemDashboardInventario');
    let _filtroDashboardMesas = this.getStoredItemsByName('listagemDashboardMesas');
    let _filtroDashboardMonitoramentoLink = this.getStoredItemsByName('listagemDashBoardMonitoramentoLink');
    let _filtroDashboardPrincipal = this.getStoredItemsByName('listagemDashboardPrincipal');
    let _filtroDashboardSla = this.getStoredItemsByName('listagemDashboardSla');
    let _filtroDashboardTarefas = this.getStoredItemsByName('listagemDashboardTarefas');
    let _filtroDashboardTecnico = this.getStoredItemsByName('listagemDashboardTecnico');
    let _filtroDashboardVulnerabilidade = this.getStoredItemsByName('listagemDashboardVulnerabilidade');

    // save ticket logs filter
    let _filtroTicketLogs = this.getStoredItemsByName('filtroTicketLogs');

    let _dados_browser = localStorage.getItem('dados_browser');

    let _deviceId = localStorage.getItem('deviceId');

    let lembrarDispositivo = localStorage.getItem('lembrarDispositivo');

    localStorage.clear();

    this.removeStoredItem("stopwatchSoundAlert");
    this.removeStoredItem("stopwatchRefreshDashboard");

    localStorage.setItem('theme', _tema);

    this.setStoredItemsByName('listagemDashboardTicket', _filtroDashboardTicket);
    this.setStoredItemsByName('listagemCanaisAtendimento', _filtroDashboardCanaisAtendimento);
    this.setStoredItemsByName('listagemDashboardChat', _filtroDashboardChat);
    this.setStoredItemsByName('listagemDashboardUsersStatus', _filtroDashboardUsersStatus);
    this.setStoredItemsByName('listagemDashboardClassificacaoInterna', _filtroDashboardClassificacaoInterna);
    this.setStoredItemsByName('listagemDashboardClassificacaoTicketCliente', _filtroDashboardClassificacaoTicketCliente);
    this.setStoredItemsByName('listagemDashboardDashboard', _filtroDashboardDashboard);
    this.setStoredItemsByName('listagemDashboardInventario', _filtroDashboardInventario);
    this.setStoredItemsByName('listagemDashboardMesas', _filtroDashboardMesas);
    this.setStoredItemsByName('listagemDashBoardMonitoramentoLink', _filtroDashboardMonitoramentoLink);
    this.setStoredItemsByName('listagemDashboardPrincipal', _filtroDashboardPrincipal);
    this.setStoredItemsByName('listagemDashboardSla', _filtroDashboardSla);
    this.setStoredItemsByName('listagemDashboardTarefas', _filtroDashboardTarefas);
    this.setStoredItemsByName('listagemDashboardTecnico', _filtroDashboardTecnico);
    this.setStoredItemsByName('listagemDashboardVulnerabilidade', _filtroDashboardVulnerabilidade);

    this.setStoredItemsByName('filtroTicketLogs', _filtroTicketLogs);

    if (environment.allowFiltroNovo && (_usaFiltroNovo !== null && _usaFiltroNovo !== undefined)) {
      localStorage.setItem('usa_filtro_novo', _usaFiltroNovo);
    }
    if (_dados_browser) {
      localStorage.setItem('dados_browser', _dados_browser);
    }
    if (_deviceId) {
      localStorage.setItem('deviceId', _deviceId);            
    }
    if(lembrarDispositivo) {
      localStorage.setItem('lembrarDispositivo', lembrarDispositivo);
    }

    if(!noReload) {
      window.location.reload();
    }
  }
}
