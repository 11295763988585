import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, Subscription } from "rxjs";
import { CategoryCatalogueService } from "../../../services/category-catalogue.service";

@Component({
    selector: 'cat-multi-select',
    templateUrl: './categories-ml-select.html',
    styleUrls: ['./categories-ml-select.scss']
})
export class CategoriesMultiLevelSelectComponent implements OnInit, OnDestroy, AfterViewInit {
    // Novos parâmetros para chamar api por demanda
    @Input() fromEditTicket;
    canLoadResources: boolean = false;
    customer: number;
    @Input() set customer_id(value) {
        if(value) {
            setTimeout(() => {
                this.filterDropdownById(value);
            }, 500);
        }
    };
    @Input() set model(value) {
        this.setSelectValueReceived(value);
    }
    @Input() required: boolean;
    @Input() requiredSecondary: boolean;
    @Input() complete: boolean;
    @Input() primarySecondary: boolean;
    @Input() disabled: boolean;
    @Input() selectOnlySecondary: boolean;
    @Input() params: any;
    @Input() all: boolean;
    @Input() showOnlyPrimary: boolean;
    @Input() set primaryToFilter(value) {
        this.primaryId = value;
    };
    @Input() showOnlySecondary: boolean;
    @Input() selectOnlyLastSub: boolean;
    @Output() categoryChange = new EventEmitter;
    categories: any[] = [];
    selectedCategory: any;
    newSubs: Subscription;
    ellipsisLabel: number = 0;
    receivedValue: any;
    primaryId: any;
    @ViewChild('btnSelectCatTree') selectCatTreeHtml: ElementRef;
    public emitEventToCategoriesTree: Subject<void> = new Subject<void>();

    constructor(
        private categoryService: CategoryCatalogueService,
        private translate: TranslateService,
        private spinner: NgxSpinnerService
    ) {}

    ngOnInit(): void {
        if (!this.customer_id) {
            if (this.fromEditTicket) {
                if (this.canLoadResources) {
                    this.getTree();
                }
            } else {
                this.getTree();
            }
        }
    }

    ngOnDestroy(): void {
        this.newSubs?.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.calcLabelEllipsis();        
    }

    getTree(): void {
        this.newSubs = this.categoryService.getTree(this.params)
            .subscribe({
                next: async (results) => {
                    this.categories = results;
                    if (this.all) {
                        this.categories.unshift({
                            id: "__all__",
                            nome: this.translate.instant('TODOS'),
                            sub: []
                        });
                    }
                    if (this.categories.length > 0 && this.receivedValue) {
                        let listClassified = await this.classifyCategories(this.categories);
                        this.findAndSelectValueReceived(this.receivedValue, listClassified);
                    }
                }
            });
    }

    async setSelectValueReceived(value: any): Promise<void> {
        if (value) {
            if(this.fromEditTicket && value.data) {
                let path_ids = (value.data.ids && typeof(value.data.ids) === "string") ? value.data.ids.replaceAll(' ', '') : value.data.ids;
                this.selectedCategory = {
                    id: value.id,
                    text: value.data.labels.replaceAll(' / ', '/'),
                    path_ids: path_ids,
                    primary: (typeof(path_ids) === "string") 
                        ? (path_ids.split('/')[1]) ? path_ids.split('/')[0] : null
                        : path_ids
                };
            }
            if (value.id) {
                this.receivedValue = (value.id !== '__all__') ? Number(value.id) : value.id;
            } else {
                this.receivedValue = (value !== '__all__') ? Number(value) : value;
            }
        } else if (this.receivedValue) {
            this.receivedValue = null;
            this.clear();
        }
    }

    findAndSelectValueReceived(category_id: number, categoryList: any): void {
        if (categoryList) {
            categoryList.forEach(category => {
                if (category.id === category_id) {
                    this.selectedCategory = {
                        id: category.id,
                        text: category.select_label,
                        path_ids: category.path_ids,
                        primary: (category.path_ids.split('/')[1]) ? category.path_ids.split('/')[0] : null
                    };
                } else if (category.sub.length > 0) {
                    this.findAndSelectValueReceived(category_id, category.sub);
                }
            });
        }
    }

    async classifyCategories(categories: any[], father_name?, father_id?): Promise<any> {
        categories.forEach(async category => {
            category.selected = false;
            category.layer = (father_name) ? 2 : 1;
            category.select_label = (father_name) ? father_name + '/' + category.nome : category.nome;
            category.path_ids = (father_id) ? father_id + '/' + category.id : category.id.toString();

            if (category.sub.length > 0) {
                category.sub = await this.classifyCategories(category.sub, category.select_label, category.path_ids);
            }
        });

        return categories;
    }

    calcLabelEllipsis(): void {
        this.ellipsisLabel = (this.selectCatTreeHtml.nativeElement.scrollWidth / 11);
    }

    filterDropdownById(customer: any): void {
        this.customer = customer.id ? customer.id : customer;
        if (this.fromEditTicket) {
            if (this.canLoadResources) {
                this.getDropdownById(customer);
            }
        } else {
            this.getDropdownById(customer);
        }
    }

    getDropdownById(customer: any): void {
        let _params = {
            cliente_id: (customer.id) ? customer.id : customer,
        };

        if (this.params) {
            _params = {..._params, ...this.params};
        }

        this.newSubs = this.categoryService.getTreeByCustomer(_params)
            .subscribe({
                next: async (results) => {
                    this.categories = results;
                    if (this.all) {
                        this.categories.unshift({
                            id: "__all__",
                            nome: this.translate.instant('TODOS'),
                            sub: []
                        });
                    }
                    if (this.categories.length > 0 && this.receivedValue) {
                        let listClassified = await this.classifyCategories(this.categories);
                        this.findAndSelectValueReceived(this.receivedValue, listClassified);
                    }
                }
            });
    }

    setCategory(category): void {
        if (category) {            
            if (this.primarySecondary) {
                let _idsSplitted = category.path_ids.split('/');
    
                if (_idsSplitted.length > 1) {
                    category.primary = _idsSplitted[0];
                }
    
                this.selectedCategory = category;
                this.categoryChange.emit(this.selectedCategory);
            } else {
                if (!this.complete) {
                    delete category['path_ids'];
                }
        
                this.selectedCategory = category;
                this.categoryChange.emit(this.selectedCategory);
            }
        } else {
            this.selectedCategory = null;
            this.categoryChange.emit(this.selectedCategory);
        }
    }

    verifyRequired(): boolean {
        if(this.required && this.requiredSecondary) {
            return !this.selectedCategory || !this.selectedCategory.primary || this.selectedCategory.id === this.selectedCategory.primary;
        } else {
            if (this.required && !this.selectedCategory) {
                return true;
            } else {
                return false;
            }
        }
    }

    clear(): void {
        this.selectedCategory = null;
        this.categoryChange.emit(null);
        this.emitEventToCategoriesTree.next();
    }

    verifyLoadResources(): void {
        if (this.fromEditTicket && !this.canLoadResources) {
            this.spinner.show();
            this.canLoadResources = true;
            if (this.customer) {
                this.getDropdownById(this.customer);
            } else {
                this.getTree();
            }
            setTimeout(() => {
                this.spinner.hide();
            }, 500);
        }
    }
}