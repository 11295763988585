import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class GatilhoChamadoService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() }),
      params: {}
  };

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  validarAprovacao(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/chamados/aprovacao/validar`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getListagemWorkflow(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/gatilho-chamados/listagem`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  createWorkflow(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/gatilho-chamados`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateWorkflow(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/gatilho-chamados`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getWorkflowById(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/gatilho-chamados/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  pausarIniciarWorkflow(acao, id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/gatilho-chamados/${acao}/${id}`, '', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteWorkflow(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.delete(`${this.apiUrl}api/gatilho-chamados/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /* DROPDOWNS */
  getDropdownTipo(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/gatilho-chamados/dropdown-tipo`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownCondicoes(params?): Observable<any> {
    this.httpOptions.params = params ? params : '';
    return this.http.get(`${this.apiUrl}api/gatilho-chamados/dropdown-condicoes`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownOperadores(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/gatilho-chamados/dropdown-operadores`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownValor(type): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/gatilho-chamados/obter-informacoes/${type}?chave=${type}&is_ativo=__all__&filtro_geral=true`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownTipoAcao(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/gatilho-chamados/dropdown-tipo-acoes`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownTipoAtendimento(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/gatilho-chamados/dropdown-tipo-atendimento`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownAcao(params?: any): Observable<any> {
    this.httpOptions.params = params ? params : '';
    return this.http.get(`${this.apiUrl}api/gatilho-chamados/dropdown-acoes`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownDestinatario(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/gatilho-chamados/dropdown-destinatarios`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
