import { Component, ElementRef, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ClienteContatoService } from 'src/app/services/cliente-contato.service';
import { environment } from 'src/environments/environment';
import { PhonePipe } from 'src/app/shared/validators/pipes/phone.pipe';
import { QuillEditorComponent } from 'ngx-quill';
import { QuillConfigModel } from 'src/app/models/quill/quillConfig.model';
import { QuillButtonOptionsModel } from 'src/app/models/quill/quillButtonOptions.model';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
@Component({
  selector: 'app-modal-criar-contato-cliente',
  templateUrl: './modal-criar-contato-cliente.component.html',
  styleUrls: ['./modal-criar-contato-cliente.component.scss']
})
export class ModalCriarContatoClienteComponent implements OnInit, OnDestroy {
  @Input() novoContatoData;
  clienteContato = {
    is_padrao: "",
    descricao: "",
    email: "",
    codigo_pais: "",
    codigo_pais_id: "",
    telefone: "",
    celular: "",
    possui_whatsapp: "",
    observacao: ""
  };
  create = true;
  selector: string;
  is_ddg: boolean = false;
  newSubs: Subscription;
  codigo_pais: any;
  mascaraTel = ''
  mascaraCel = ''
  render: boolean = true;
  quillConfigEditor: QuillConfigModel;
  editor: QuillEditorComponent;
  observacaoForm: UntypedFormGroup;
  textEditorDisabledImage = true;

  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    public translate: TranslateService,
    private clienteContatoService: ClienteContatoService,
    private elemRef: ElementRef,
    private phonePipe: PhonePipe,
    private _fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef
  ) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
  }

  changeDdi(event) {
    if (event === undefined) {
      this.clienteContato.codigo_pais = ''
      this.clienteContato.codigo_pais_id = ''
      if (!this.novoContatoData.contato) {
        this.clienteContato.telefone = ''
        this.clienteContato.celular = ''
      }
      return
    }
    this.render = false;
    this.clienteContato.codigo_pais = event && event.valor ? event.valor : '';
    this.clienteContato.codigo_pais_id = event && event.id ? event.id : null;
    this.mascaraTel = this.setMascara(this.clienteContato.celular, event.valor)
    this.mascaraCel = this.setMascara(this.clienteContato.telefone, event.valor)
    this.cdRef.detectChanges();
    this.render = true;
  }

  checkDdg($event) {
    this.is_ddg = $event.target.checked
  }

  bulidForm() {
    this.observacaoForm = this._fb.group({
      observacao: [this.clienteContato.observacao],
    });
  }

  setQuillEditorConfig() {
    this.quillConfigEditor = {
      magicUrl: true,
      imageDrop: false,
      ImageResize: { modules: ["Resize", "DisplaySize", "Toolbar"] },
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          /* [
            { align: [] },
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ], */

          //["code-block", "link", "image"],
        ],
        handlers: { emoji: function () { } },
      },
      "toolbar-custom-button": new QuillButtonOptionsModel(),
      "emoji-toolbar": true,
      "emoji-textarea": false,
      "emoji-shortname": true,

      // mention: {
      //   allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      //   onSelect: (item, insertItem) => {
      //     const editor = this.editor.quillEditor;
      //     insertItem(item);
      //     editor.insertText(editor.getLength() - 1, "", "user");
      //   },
      //   source: (searchTerm, renderList) => {
      //     const values = [
      //       { id: 1, value: "Fredrik Sundqvist" },
      //       { id: 2, value: "Patrik Sjölin" },
      //     ];

      //     if (searchTerm.length === 0) {
      //       renderList(values, searchTerm);
      //     } else {
      //       const matches = [];

      //       values.forEach((entry) => {
      //         if (
      //           entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
      //           -1
      //         ) {
      //           matches.push(entry);
      //         }
      //       });
      //       renderList(matches, searchTerm);
      //     }
      //   },
      // },
      keyboard: {
        bindings: {
          // shiftEnter: {
          //   key: 13,
          //   shiftKey: true,
          //   handler: (range, context) => {
          //     // Handle shift+enter
          //     console.log("shift+enter")
          //   }
          // },
          enter: {
            key: 13,
            handler: (range, context) => {
              return true;
            },
          },
        },
      },
    };
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    this.setQuillEditorConfig();

    if (this.novoContatoData.contato) {
      this.create = false;
      this.clienteContato = this.novoContatoData.contato;
      this.bulidForm();
      this.clienteContato.email = (this.clienteContato.email === null) ? "" : this.clienteContato.email;
      this.is_ddg = this.clienteContato.telefone && this.clienteContato.telefone.includes('0800') ? true : false;
      this.codigo_pais = this.novoContatoData.contato.codigo_pais ? { id: this.novoContatoData.contato.codigo_pais_id, valor: this.novoContatoData.contato.codigo_pais } : {};
      if (this.codigo_pais) {
        this.changeDdi(this.codigo_pais)
      } else {
        this.changeDdi(undefined)
      }
    }
    this.eventPaste();
  }

  ngOnDestroy(): void {
    this.textEditorDisabledImage = false;
    document.removeEventListener('paste', this.eventPaste, false);
    this.newSubs?.unsubscribe();
  }

  eventPaste() {
    let _this = this;
    document.addEventListener('paste', function (event) {
      var text = event.clipboardData.getData(
        "text/html"
      );
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      var image = doc.querySelector("img");
      if (image && _this.textEditorDisabledImage) {
        event.preventDefault();
        return;
      }
    }, false);
  }

  verificaSeDesativa(contato): boolean {
    if (contato.is_padrao === "" || contato.descricao === "" || contato.descricao.length < 3) {
      return true;
    } else {
      if (contato.email !== "") {
        if (this.validateEmail(contato.email) === true) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  verificaRequerido(descricao): boolean {
    if (descricao === "" || descricao.length < 3) {
      return true;
    } else {
      return false;
    }
  }

  validateEmail(email): boolean {
    if (email === '') {
      return true;
    } else {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }

  salvaContato(contato): void {
    if (this.create === true) {
      var _contato = {
        clienteContato: {
          is_padrao: contato.is_padrao,
          descricao: contato.descricao,
          email: contato.email,
          telefone: contato.telefone,
          celular: contato.celular,
          possui_whatsapp: (contato.possui_whatsapp === '') ? false : contato.possui_whatsapp,
          observacao: this.observacaoForm && this.observacaoForm.get('observacao').value && this.observacaoForm.get('observacao').value.hasOwnProperty('html') ? this.observacaoForm.get('observacao').value.html : contato.observacao,
          cliente_id: this.novoContatoData.cliente,
          codigo_pais: this.clienteContato.codigo_pais,
          codigo_pais_id: this.clienteContato.codigo_pais_id
        }
      };
      this.newSubs = this.clienteContatoService.createClienteContato(_contato)
        .subscribe(results => {
          this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
          this.modal.close();
        }, error => {
          console.log(error);
          this.toastr.show(error, '', environment.toastrConfig('danger'));
        })
    } else {
      var _contatoUpdate = {
        clienteContato: {
          ...contato,
          codigo_pais: this.clienteContato.codigo_pais,
          codigo_pais_id: this.clienteContato.codigo_pais_id,
          observacao: this.observacaoForm.get('observacao').value && this.observacaoForm.get('observacao').value.html ? this.observacaoForm.get('observacao').value.html : contato.observacao
        }
      };

      this.newSubs = this.clienteContatoService.update(_contatoUpdate)
        .subscribe(results => {
          this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
          this.modal.close();
        }, error => {
          console.log(error);
          this.toastr.show(error, '', environment.toastrConfig('danger'));
        })
    }
  }

  setMascara(telefone, ddi) {
    return this.phonePipe.showMascaraInput(telefone, ddi)
  }
}
