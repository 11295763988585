import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  config;
  paginaAtual: number;
  @Input() set meta (value) {
    if(this.config === undefined) {
      this.config = value;
      this.paginaAtual = Number(this.config.current_page);    
    } else {
      this.setMeta(value);
    }
  };
  @Input() mini;
  @Output() pageChange = new EventEmitter();
  paginasVisiveis: Array<Number> = [];
  @Input() dados_gerais;
  @Input() listagem;
  @Input() paginationType?: string = 'base';
  
  constructor(
    private cd: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    if ((this.config.to > this.config.total) && this.config.total > 0) {          
      this.config.to = this.config.total;
    }
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.paginaAtual = Number(this.config.current_page);   

    if (this.paginaAtual > 2) {
      if (this.paginaAtual === Number(this.config.last_page)) {        
        for (let i = this.paginaAtual-2; i < this.paginaAtual+1; i++) {
          this.paginasVisiveis.push(i);                
        }
      } else {
        for (let i = this.paginaAtual-1; i < this.paginaAtual+2; i++) {
          this.paginasVisiveis.push(i);                
        }
      }     
    } else {
      if(this.config.last_page > 3) {
        this.paginasVisiveis = [1, 2, 3];
      } else {
        for(let i = 1; i <= this.config.last_page; i++) {
          this.paginasVisiveis.push(i);
        }
      }
    }
  }

  setMeta(meta): void {
    this.config = meta;
    if ((this.config.to > this.config.total) && this.config.total > 0) {          
      this.config.to = this.config.total   
    }

    this.paginaAtual = Number(this.config.current_page);

    if(this.paginaAtual === 1) {
      this.paginasVisiveis = []
      if(this.config.last_page > 3) {
        this.paginasVisiveis = [1, 2, 3];
      } else {
        for(let i = 1; i <= this.config.last_page; i++) {
          this.paginasVisiveis.push(i);
        }
      }
    }
    
    if(this.paginasVisiveis.indexOf(this.config.last_page) === -1) {
      if(this.paginaAtual === (this.config.last_page -1)) {
        if(this.paginasVisiveis.length < 3) {
          this.paginasVisiveis.push(this.config.last_page);
        } else if(this.paginaAtual === this.paginasVisiveis[2]) {
          this.paginasVisiveis.shift();
          this.paginasVisiveis.push(this.config.last_page);
        }
      }
    }

    if(this.paginasVisiveis[2] > this.config.last_page) {
      this.paginasVisiveis.pop();
      var paginaAnterior = Number(this.paginasVisiveis[0]) - 1;
      this.paginasVisiveis.unshift(paginaAnterior);
    }

    setTimeout(() => {
      if(this.paginaAtual > this.config.last_page) {
        this.paginaAtual = this.config.last_page;
        this.pageChange.emit(this.paginaAtual);
      }
    }, 1000);
  }

  setPage(page): void {
    var _event;
    if(page === 'next') {
      if(Number(this.config.current_page) < Number(this.config.last_page)) {
        _event = Number(this.config.current_page) + 1;
        this.paginaAtual = _event;
        this.setPaginasVisiveis();
      } else {
        return;
      }
    } else if(page === 'previous') {
      if(Number(this.config.current_page) > 1) {
        _event = Number(this.config.current_page) - 1;
        this.paginaAtual = _event;
        this.setPaginasVisiveis();
      } else {
        return;
      }
    } else if(page === 'first') {
      if(Number(this.config.current_page) > 1) {
        _event = 1;
        this.paginaAtual = 1;
        this.setPaginasVisiveis('first');
      } else {
        return;
      }
    } else if(page === 'last') {
      if(Number(this.config.current_page) < Number(this.config.last_page)) {
        _event = this.config.last_page;
        this.paginaAtual = this.config.last_page;
        this.setPaginasVisiveis('last');
      } else {
        return;
      }
    } else {
      _event = page;
      this.paginaAtual = _event;
      this.setPaginasVisiveis();
    }

    this.pageChange.emit(_event);
  }

  setPaginasVisiveis(item?): void {
    if(item) {
      this.paginasVisiveis = [];
      if(item === 'first') {
        if(this.config.last_page > 3) {
          for(let i = 1; i <= this.config.last_page-2; i++) {
            this.paginasVisiveis.push(i);
            if(i === 3) {
              break;
            }
          }
        } else {
          for(let i = 1; i <= 3; i++) {
            this.paginasVisiveis.push(i);
            if(i === 3) {
              break;
            }
          }
        }
      } else if(item === 'last') {
        this.paginasVisiveis = [];
          var _primeira = this.config.last_page - 2;
          
          for(let i = 0; i < 3; i++) {
            if ((_primeira + i) !== 0) {
              this.paginasVisiveis.push((_primeira + i));              
            }
          }
      }
    } else {
      this.paginasVisiveis.forEach((pagina, index) => {
        if(pagina === this.paginaAtual) {
          if(index === (this.paginasVisiveis.length - 1)) {
            var _proximaPagina = this.paginaAtual + 1;
            if(_proximaPagina <= this.config.last_page) {
              this.paginasVisiveis.push(_proximaPagina);
              this.paginasVisiveis.shift();
            }
          } else if(index === 0) {
            var _paginaAnterior = this.paginaAtual - 1;
            if(_paginaAnterior >= 1) {
              this.paginasVisiveis.unshift(_paginaAnterior);
              this.paginasVisiveis.pop();
            }
          }
        }
      });
    }
  }

  //ANALISAR SE FAZ ORDENAÇÃO AGORA OU JUNTO COM OS FILTROS, SE SIM FAZER ORDENAÇÃO

}
