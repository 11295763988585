import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
    params: {}
  };

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getDropdownTemplate(tipo_template_id?): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/template-resposta/dropdown/${tipo_template_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  traduzirTemplate(template_id, chamado_id): Observable<any> {
    this.httpOptions.params = {chamado_id: chamado_id};
    return this.http.get(`${this.apiUrl}api/template-resposta/traducao/${template_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropdownComentarios() : Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/template-resposta/dropdown`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );

  }
  dropdownTemplateTipoResposta() : Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/template-resposta/dropdown-tipos`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
