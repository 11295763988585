import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/services/chat.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-transferencia-ticket',
  templateUrl: './modal-transferencia-ticket.component.html',
  styleUrls: ['./modal-transferencia-ticket.component.scss']
})
export class ModalTransferenciaTicketComponent implements OnInit, OnDestroy {

  // workflowData: GatilhoDispositivo = new GatilhoDispositivo();
  @Input() chatData;
  tipoTransferencia: any;
  fila: any;
  tecnico: any = {};
  selector: string;
  newSubs: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    public translate: TranslateService,
    private chatService: ChatService,
    private elemRef: ElementRef,
  ) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  changeTecnico(event) {
    this.tecnico = event;
  }

  changeFila(event) {
    this.fila = event;
  }

  transferirChat(): void {
    if (this.tipoTransferencia === 1) {
      let params = {
        "fila_id": this.fila.id
      }
      this.newSubs = this.chatService.transferirFilas(params, this.chatData._id)
        .subscribe((res) => {
          this.toastr.show(this.translate.instant('TRANSFERIDO_SUCESSO'), "", environment.toastrConfig("success"));
          this.close({"status":"ok"});
        }, (error) => {
          console.log(error)
          this.toastr.show(error, "", environment.toastrConfig("danger"));
        })
    } else {
      let params = {
        "tecnico_id": this.tecnico.id
      }
      this.newSubs = this.chatService.transferirTecnico(params, this.chatData._id)
        .subscribe((res) => {
          this.toastr.show(this.translate.instant('TRANSFERIDO_SUCESSO'), "", environment.toastrConfig("success"));
          this.close({"status":"ok"});
        }, (error) => {
          console.log(error)
          this.toastr.show(error, "", environment.toastrConfig("danger"));
        })
    }
  }

  close(result?) {
    this.activeModal.close(result);
  }

}
