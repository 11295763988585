import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { SidebarComponent } from "./sidebar/sidebar.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from "./footer/footer.component";

import { RouterModule } from "@angular/router";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from "@angular/common/http";
import { ModalModule } from "./modais/modal.module";
import { SelectsModule } from "./selects/selects.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StarsComponent } from './stars/stars.component';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { PipesModule } from "src/app/shared/validators/pipes/pipes.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FiltrosModule } from "./filtros/filtros.module";
import { TableSelectModule } from './table-select/table-select.module';
import { TelaTutorialComponent } from './tela-tutorial/tela-tutorial.component';
import { ModalVideoTutorialComponent } from './tela-tutorial/modal-video-tutorial/modal-video-tutorial.component';
import { MilvusTreePadraoModule } from "./milvus-tree-padrao/milvus-tree-padrao.module";
import { ImgCropperModule } from './image-cropper/image-cropper.module';
import { PaginationModule } from "./pagination/pagination.module";
import { DatepickerPadraoModule } from "./datepicker-padrao/datepicker-padrao.module";
import { ColorPickerModule } from './color-picker/color-picker.module';
import { MinhaAgendaPortuguesModule } from "./minha-agenda-portugues/minha-agenda-portugues.module";
import { HourpickerPadraoModule } from "./hourpicker-padrao/hourpicker-padrao.module";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { ExportarRelatorioPersonalizadoComponent } from './exportar-relatorio-personalizado/exportar-relatorio-personalizado.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ScreensPathComponent } from './screens-path/screens-path.component';
import { AutosizeModule } from "ngx-autosize";
import { FiltrosPadraoComponent } from './filtros-padrao/filtros-padrao.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalFiltrosComponent } from './filtros-padrao/modal-filtros/modal-filtros.component';
import { PowershellComponent } from './powershell/powershell.component';
import { CategoriesMultiLevelModule } from "./categories-multi-level/categories-multi-level.module";
import { TvModeComponent } from './tv-mode/tv-mode.component';
import { NoHtmlFormatterModule } from "./no-html-formatter/no-html-formatter.module";
import { BotoesAtendimento } from "./botoes-atendimento/botoes-atendimento.component";
import { ConfirmarDoisFatoresComponent } from "./confirmar-dois-fatores/confirmar-dois-fatores.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CollapseModule.forRoot(),
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ModalModule,
    SelectsModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    PipesModule,
    NgbModule,
    FiltrosModule,
    TableSelectModule,
    MilvusTreePadraoModule,
    ImgCropperModule,
    PaginationModule,
    DatepickerPadraoModule,
    ColorPickerModule,
    ColorPickerModule,
    MinhaAgendaPortuguesModule,
    HourpickerPadraoModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    AlertModule,    
    AutosizeModule,
    AlertModule,
    TabsModule.forRoot(),
    CategoriesMultiLevelModule,
    NoHtmlFormatterModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    //PaginationComponent,
    // FiltrosComponent,
    StarsComponent,
    TelaTutorialComponent,
    ModalVideoTutorialComponent,
    ExportarRelatorioPersonalizadoComponent,
    ScreensPathComponent,
    FiltrosPadraoComponent,
    ModalFiltrosComponent,
    PowershellComponent,
    TvModeComponent,
    BotoesAtendimento,
    ConfirmarDoisFatoresComponent,
    // ChatGptComponent,
    // ButtonComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    //PaginationComponent,
    // FiltrosComponent,
    StarsComponent,
    TelaTutorialComponent,
    ScreensPathComponent,
    FiltrosPadraoComponent,
    TvModeComponent,
    BotoesAtendimento,
    ConfirmarDoisFatoresComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class ComponentsModule {}
