<div class="modal-content" style="zoom: 0.85;">
    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">
            {{'INICIAR_CONVERSA_WPP' | translate}} 
            {{' ' + (data.number | mask: getMaskByCountry(data.number))}}
        </h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="data.provider_id === 4; else zapfy">
            <div class="row divider-bottom">
                <div class="col-6 form-group">
                    <label>Template</label>
                    <select-padrao [entidade]="'selectWppOfficialTemplate'" [returnObj]="true" [params]="data.integration_id" (selectChange)="changeTemplateProperties($event)"></select-padrao>
                </div>
            </div>
            <div *ngIf="template_content" class="row mt-2">
                <div class="col-12 form-group">
                    <label [translate]="'MENSAGEM'"></label>
                    <br>
                    <span class="">{{template_content}}</span>
                </div>
            </div>
        </ng-container>
        <ng-template #zapfy>
            <div class="row">
                <div class="col-12 form-group">
                    <label [translate]="'MENSAGEM'"></label>
                    <textarea class="form-control mt-2" rows="10" [(ngModel)]="content.mensagem"></textarea>
                </div>
            </div>
        </ng-template>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success badge-pill" [translate]="'INICIAR_CONVERSA'" (click)="close(content)" [disabled]="verifyDisabled()"></button>
    </div>
</div>