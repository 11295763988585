import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TokenService } from "../core/token/token.service";

@Injectable({
  providedIn: "root",
})
export class CategoryGroupsService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      authorization: this.tokenService.getToken(),
    }),
    params: {},
  };

  constructor(private tokenService: TokenService, private http: HttpClient) {}

  getList(params, payload: any): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/grupo-categoria/grupo/listar`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  duplicateGroup(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.patch(
      `${this.apiUrl}api/grupo-categoria/grupo/duplicar`,
      payload,
      this.httpOptions
    )
    .pipe(catchError(this.handleError));
  }

  updateGroup(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(
      `${this.apiUrl}api/grupo-categoria/grupo/atualizar`,
      payload,
      this.httpOptions
    )
    .pipe(catchError(this.handleError));
  }

  createGroup(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(
      `${this.apiUrl}api/grupo-categoria/grupo/criar`,
      payload,
      this.httpOptions
    )
    .pipe(catchError(this.handleError));
  }

  getRelatedCategoriesList(group_id: number): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(
      `${this.apiUrl}api/grupo-categoria/relacionamento/buscar/${group_id}`,
      this.httpOptions
    )
    .pipe(catchError(this.handleError));
  }

  relateCategory(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(
      `${this.apiUrl}api/grupo-categoria/relacionamento/criar`,
      payload,
      this.httpOptions
    )
    .pipe(catchError(this.handleError));
  }

  unrelateCategory(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.request(
      'delete',
      `${this.apiUrl}api/grupo-categoria/relacionamento/remover`,
      {
        body: payload,
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()})
      }
    )
    .pipe(catchError(this.handleError));
  }

  dropdownGroups(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(
      `${this.apiUrl}api/grupo-categoria/grupo/dropdown`,
      this.httpOptions
    )
    .pipe(catchError(this.handleError));
  }

  relateGroupToCustomer(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(
      `${this.apiUrl}api/grupo-categoria/cliente/vincular`,
      payload,
      this.httpOptions
    )
    .pipe(catchError(this.handleError));
  }

  getCustomerCategoryGroups(customer_id: number, params: any): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(
      `${this.apiUrl}api/grupo-categoria/cliente/listar/${customer_id}`,
      this.httpOptions
    )
    .pipe(catchError(this.handleError));
  }

  unrelateGroupToCustomer(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.request(
      'delete',
      `${this.apiUrl}api/grupo-categoria/cliente/desvincular`,
      {
        body: payload,
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()})
      }
    )
    .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("Ops! Ocorreu um erro:", error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
          `o body é: ${error.error}`
      );
    }
    return throwError(error.error);
  }
}
