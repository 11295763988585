import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class ClienteEnderecoService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
    params: {}
  };

  constructor(private tokenService: TokenService, private http: HttpClient) { }

  getListagemEnderecos(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/clienteendereco/list`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  validaCep(cep): Observable<any> {
    this.httpOptions.params = cep;
    return this.http.get(`${this.apiUrl}api/clienteendereco/cep/validar`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  createEndereco(endereco): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/clienteendereco`, endereco, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizaEndereco(endereco): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/clienteendereco`, endereco, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteEndereco(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/clienteendereco/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getEnderecoCliente(cliente_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/clienteendereco/padrao/${cliente_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /* DROPDOWN */
  getClienteEnderecoById(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/clienteendereco/dropdown`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
