<div class="modal-content">
    <div class="modal-header">
        <h6 *ngIf="create" class="modal-title" id="modal-title-default" [translate]="'CRIAR_FILTRO'"></h6>
        <h6 *ngIf="!create" class="modal-title" id="modal-title-default" [translate]="'EDITAR_FILTRO'"></h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="modal-body" [formGroup]="filtroForm">
        <div class="row">
            <div class="col-6">
                <label [translate]="'NOME_FILTRO'"></label>
                <input type="text" class="form-control" [formControl]="filtroForm.controls.nome" required [ngClass]="{'required': filtroForm.controls['nome'].hasError('required')}">
            </div>
            <div class="col-6">
                <label [translate]="'VISIBILIDADE'"></label>
                <select-padrao [entidade]="'selectVisibilidadeFiltros'" [model]="{id: filtroForm.value.visibilidade_id, text: ''}" [required]="true" (selectChange)="filtroForm.patchValue({visibilidade_id: $event})"></select-padrao>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <label [translate]="'CONDICOES_FILTRO'"></label>
                <div class="mt-2 condicoes-filtro">
                    <ng-container *ngIf="filtroForm.controls.condicoes.value.length > 0" formArrayName="condicoes">
                        <div class="filtro-condicoes" *ngFor="let condicao of filtroForm.get('condicoes').controls; trackBy:trackByIndex let i = index" [formGroupName]="i">
                            <i class="timeline-condicao-icon mdi mdi-circle-slice-8"></i>
                            <select-padrao class="selects-filtro-condicoes" [entidade]="'selectEntidadeFiltros'" [customPlaceholder]="'ENTIDADE'" [model]="{id: (filtro_id ? condicoesRecebidas[i].entidade_id : null), text:''}" [required]="true" (selectChange)="condicao.patchValue({entidade_id: $event})"></select-padrao>
                            
                            <select-padrao class="selects-filtro-condicoes" [entidade]="'selectCamposFiltros'" [customPlaceholder]="'CAMPO'" [model]="{id: (filtro_id ? condicoesRecebidas[i].campo_id : null), text:''}" [required]="true" [returnObj]="true" (selectChange)="patchCampoCondicao($event, condicao)"></select-padrao>
                            
                            <select-padrao class="selects-filtro-condicoes" [entidade]="'selectOperadoresFiltros'" [customPlaceholder]="'OPERADOR'" [model]="{id: (filtro_id ? condicoesRecebidas[i].operador_id : null), text:''}" [disabled]="!condicao.value.tipo_campo_id || condicao.value.blockOperator" [required]="true" [params]="condicao.value.tipo_campo_id" (selectChange)="condicao.patchValue({operador_id: $event})"></select-padrao>

                            <input *ngIf="condicao.controls.tipo_campo_id.value === 2" type="text" class="form-control inputs-filtro-condicoes" formControlName="valor" [ngClass]="{'required': condicao.controls.valor.hasError('required')}">

                            <select *ngIf="condicao.controls.tipo_campo_id.value === 3" class="form-control inputs-filtro-condicoes" formControlName="data_dinamica" (change)="changeDateType($event, condicao)">
                                <option [value]="false" [translate]="'NAO_DINAMICO'"></option>
                                <option [value]="true" [translate]="'DINAMICO'"></option>
                            </select>

                            <input *ngIf="condicao.controls.tipo_campo_id.value === 3 && condicao.controls.data_dinamica.value === false" type="date" class="form-control inputs-filtro-condicoes" formControlName="valor" [ngClass]="{'required': condicao.controls.valor.hasError('required')}">

                            <input *ngIf="condicao.controls.tipo_campo_id.value === 4 || condicao.controls.tipo_campo_id.value === 6" type="number" class="form-control number-input inputs-filtro-condicoes" formControlName="valor" [ngClass]="{'required': condicao.controls.valor.hasError('required')}">

                            <select *ngIf="condicao.controls.tipo_campo_id.value === 5" class="form-control inputs-filtro-condicoes" formControlName="valor" [ngClass]="{'required': condicao.controls.valor.hasError('required')}">
                                <option [value]="true" [translate]="'SIM'"></option>
                                <option [value]="false" [translate]="'NAO'"></option>
                            </select>
                            
                            <select-padrao class="selects-filtro-condicoes" *ngIf="condicao.controls.tipo_campo_id.value === 9" [multiple]="true" [customPlaceholder]="'VALOR'" [entidade]="'selectValoresCondicaoFiltro'" [required]="true" [params]="condicao.value.campo_chave" [model]="filtro_id ? condicoesRecebidas[i].valor : null" [returnObj]="false" (selectChange)="condicao.patchValue({valor: $event})"></select-padrao>

                            <select-padrao class="selects-filtro-condicoes" *ngIf="condicao.controls.tipo_campo_id.value === 7" [customPlaceholder]="'VALOR'" [entidade]="'selectValoresCondicaoFiltro'" [required]="true" [params]="condicao.value.campo_chave" [model]="filtro_id ? condicoesRecebidas[i].valor : null" [returnObj]="false" (selectChange)="condicao.patchValue({valor: {id:$event, text:''}})"></select-padrao>

                            <select-padrao class="selects-filtro-condicoes" *ngIf="condicao.value.data_dinamica" [customPlaceholder]="'VALOR'" [entidade]="'selectDataDinamicaCondicaoFiltro'" [required]="true" [params]="'$datas'" [model]="{id: (filtro_id ? condicoesRecebidas[i].valor : null), text:''}" [returnObj]="true" (selectChange)="patchValueDinamicDate($event, condicao)"></select-padrao>

                            <i ngbTooltip="{{'EXCLUIR_CONDICAO' | translate}}" placement="left" (click)="removeCondicao(i)" class="mdi mdi-close-circle icon-remove-condicao"></i>
                        </div>
                    </ng-container>
                    <button type="button" class="btn btn-md btn-success badge-pill d-flex align-items-center mt-3" (click)="novaCondicao()">
                        <i class="mdi mdi-plus font-22"></i>
                        <span class="text-add-condicao" [translate]="'ADD_CONDICAO'"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="w-100 d-flex" [ngClass]="{'justify-content-between': !create, 'justify-content-end': create}">
            <button *ngIf="!create" type="button" class="btn btn-md btn-danger badge-pill d-flex" (click)="excluirFiltro()">
                <i class="mdi mdi-delete-outline font-19"></i>
                <span [translate]="'EXCLUIR'"></span>
            </button>
            <button type="button" class="btn btn-md btn-success badge-pill d-flex" [disabled]="filtroForm.invalid" (click)="salvaFiltro(filtroForm.value)">
                <i class="mdi mdi-content-save-outline font-19"></i>
                <span [translate]="'SALVAR'"></span>
            </button>
        </div>
    </div>
</div>
